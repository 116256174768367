import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faMapMarkerAlt, faCalendarDay, faListUl, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import SubStep4 from "../subSteps/subStep4";
import ProgressBar from "../progressBar";
import API from "../../utils/utils";
import axios from "axios";
import visa from "../../auth/visa.png";
import { Form, Row, Col, FormGroup, FormLabel, FormControl, OverlayTrigger, Tooltip, FormCheck, Modal, Button } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight, FaInfoCircle, FaCalendar } from "react-icons/fa";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import DetailModal from "../subSteps/subStep5Modal/subStep5Modal";
import Loader from "../../components/Loader";
import DatePicker from "react-datepicker";
import moment from "moment";
import countryOptions from "../../auth/countryCode.json";

const Step1 = () => {
  const todaydate = new Date().toISOString().split("T")[0];
  const [options, setOptions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [currentSubStep, setCurrentSubStep] = useState(1);
  const nextSubStep = () => setCurrentSubStep((prev) => prev + 1);
  const handleClosePopup = () => setShowPopup(false);
  const prevSubStep = () => setCurrentSubStep((prev) => prev - 1);
  const urlParams = sessionStorage.getItem("mainsessionId");
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const formMode = query.get("mode");
  const editRequestId = query.get("action");
  const tdId = query.get("tdId");
  const mainsessionIdForEmai = query.get("main_session_id");
  const [apiData, setApiData] = useState([]);
  const [showSubStep4, setShowSubStep4] = useState();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        // Fetch country data
        // const response = await axios.get('https://restcountries.com/v3.1/all');
        // const countryOptions = response.data.map(country => {
        //   const callingCode = country.idd?.root + (country.idd?.suffixes ? country.idd.suffixes[0] : '');
        //   return {
        //     callingCode: callingCode,
        //     value: country.cca2,
        //     label: `${callingCode ? `(${callingCode}) ` : ''}${country.name.common}`
        //   };
        // });

        // // Sort countries alphabetically by name
        // countryOptions.sort((a, b) => a.label.localeCompare(b.label));
        // setOptions(countryOptions);

        setOptions(countryOptions);
        const ip = await getIpAddress();
        if (ip) {
          const countryCode = await getCountryCodeFromIp(ip);
          if (countryCode) {
            // const defaultOption = countryOptions.find(option => option.value === countryCode);
            const defaultOption = countryOptions.find((option) => option.countryCode === countryCode);
            if (defaultOption) {
              // setFormData(prev => ({ ...prev, countryCode: defaultOption.callingCode }));
              setFormData((prev) => ({ ...prev, countryCode: defaultOption.dialCode }));
            }
          }
        }
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };
    // setOptions(countryOptions);

    fetchCountries();
  }, []);

  // const getIpAddress = async () => {
  //   try {
  //     const response = await axios.get('https://api.ipify.org?format=json');
  //     return response.data.ip;
  //   } catch (error) {
  //     console.error("Error fetching IP address:", error);
  //     return null;
  //   }
  // };

  const getCountryCodeFromIp = async (ip) => {
    if (formMode === "add" || formMode === "edit") {
      return null;
    }
    try {
      // Replace with your IP geolocation service
      const response = await axios.get(`https://ipapi.co/${ip}/country/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching country code from IP:", error);
      return null;
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const getCountry = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=allContryDataView`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "true") {
        const formattedData = response.data.data.map((country) => ({
          ...country,
          country_name: capitalizeFirstLetter(country.country_name),
        }));
        setApiData(formattedData);
      } else {
        console.error("Failed to fetch data:", response.data.message);
      }
    } catch (error) {
      console.error("Error generating request ID:", error);
    }
  };

  useEffect(() => {
    getCountry();
  }, []);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
    if (event.target.checked) {
      setShowPopup(true);
    }
  };
  const [isRequestIdSet, setIsRequestIdSet] = useState(false);
  const [requestId, setRequestId] = useState(null);

  const getRequestId = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=generate_unique_request_id`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        const newRequestId = response.data.RequestID;
        sessionStorage.setItem("requestId", newRequestId);

        // Clear previous timeout if it exists
        if (window.requestIdTimeout) {
          clearTimeout(window.requestIdTimeout);
        }

        window.requestIdTimeout = setTimeout(() => {
          sessionStorage.removeItem("requestId");
          sessionStorage.removeItem("mainsessionId");
          sessionStorage.removeItem("country_id");
          // Reload the page
          window.location.reload();
        }, 15 * 60 * 1000);

        setIsRequestIdSet(true);
        setRequestId(newRequestId);
      } else {
        setIsRequestIdSet(false);
      }
    } catch (error) {
      console.error("Error generating request ID:", error);
      setIsRequestIdSet(false);
    }
  };

  const mainId = sessionStorage.getItem("mainsessionId");
  useEffect(() => {
    if (!mainId || (mainId && formMode === "add")) {
      getRequestId();
    }
  }, [mainId, formMode]);
  const [editData, setEditData] = useState([]);
  const getData = async () => {
    if (!urlParams) {
      console.error("No session ID found in session storage.");
      return;
    }
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=getsingleDataview&main_session_id=${urlParams}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "true") {
        setEditData(response.data.data);
        const fetchedData = response.data.data[0];

        const phoneParts = fetchedData.phone ? fetchedData.phone.split("-") : [];
        const countryCode = phoneParts[0] || "";
        const phoneNumber = phoneParts[1] || "";
        setFormData({
          Email: fetchedData.email_address || "",
          PhoneNumber: phoneNumber || "",
          countryCode: countryCode || "",
          country: fetchedData.country || "",
          arrival_date: fetchedData.arrival_date || "",
        });
       
          if (fetchedData.country==="18" || fetchedData.country==="14") {
            setFormData((prevData) => ({
              ...prevData,
              supportingDocType: 'Not Present'
            }));
          }
          if (fetchedData.arrival_date) {
            const selectedDate = moment(fetchedData.arrival_date, "YYYY-MM-DD").startOf("day");
            const newStartDate = selectedDate.format("YYYY-MM-DD");
            const newEndDate = selectedDate.clone().add(179, "days").format("YYYY-MM-DD");
  
            setStartDate(newStartDate);
            setEndDate(newEndDate);
          }
        fetchCountryQuestions(fetchedData.country);
        handleSupportCountry(fetchedData.type_of_supporting_doc, fetchedData.country);
      } else {
        console.error("Failed to fetch data:", response.data.message);
      }
    } catch (error) {
      console.error("Error generating request ID:", error);
    }
  };

  const getDataForEdit = async () => {
    const mainsessionIdForEmai = query.get("main_session_id");

    // Only use mainsessionIdForEmai if it's available, otherwise use urlParams
    const sessionId = mainsessionIdForEmai ? mainsessionIdForEmai : urlParams;

    if (!sessionId && !editRequestId) {
      console.error("No session ID found in session storage.");
      return;
    }

    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=getsingleDataview&main_session_id=${urlParams}&request_id=${editRequestId}`,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "true") {
        setEditData(response.data.data);
        const fetchedData = response.data.data[0];
        const phoneParts = fetchedData.phone ? fetchedData.phone.split("-") : [];
        const countryCode = phoneParts[0] || "";
        const phoneNumber = phoneParts[1] || "";
        setFormData({
          FastName: fetchedData.first_name || "",
          LastName: fetchedData.last_name || "",
          Email: fetchedData.email_address || "",
          PhoneNumber: phoneNumber || "",
          countryCode: countryCode || "",
          country: fetchedData.country || "",
          dateOfBirth: fetchedData.dob ? moment(fetchedData.dob, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
          placeOfBirth: fetchedData.place_of_birth || "",
          mothersName: fetchedData.mothers_name || "",
          fathersName: fetchedData.fathers_name || "",
          passportNumber: fetchedData.passport_number || "",
          passportNumberReEnter: fetchedData.passport_number || "",
          passportIssueDate: fetchedData.passport_issue_date ? moment(fetchedData.passport_issue_date, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
          passportExpiryDate: fetchedData.passport_expiry_date ? moment(fetchedData.passport_expiry_date, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
          supportingDocType: fetchedData.type_of_supporting_doc || "",
          supportingDocFrom: fetchedData.supporting_doc_from || "",
          supportingDocExpiryDate: fetchedData.supporting_doc_expiry_date ? moment(fetchedData.supporting_doc_expiry_date, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
          supportingDocNo: fetchedData.supporting_doc_no || "",
          address: fetchedData.address || "",
          read_and_accept: fetchedData.read_and_accept || "yes",
          privacy_policy: fetchedData.privacy_policy || "yes",
          refund_policy: fetchedData.refund_policy || "yes",
          arrival_date: fetchedData.arrival_date || "",
          unlimited: fetchedData.unlimited,
        });
        if (fetchedData.arrival_date) {
          const selectedDate = moment(fetchedData.arrival_date, "YYYY-MM-DD").startOf("day");
          const newStartDate = selectedDate.format("YYYY-MM-DD");
          const newEndDate = selectedDate.clone().add(179, "days").format("YYYY-MM-DD");

          setStartDate(newStartDate);
          setEndDate(newEndDate);
        }
        fetchCountryQuestions(fetchedData.country);
        handleSupportCountry(fetchedData.type_of_supporting_doc, fetchedData.country);
      } else {
        console.error("Failed to fetch data:", response.data.message);
      }
    } catch (error) {
      console.error("Error generating request ID:", error);
    }
  };

  useEffect(() => {
    if (formMode === "edit") {
      getDataForEdit();
    } else if (formMode === "add") {
      getData();
    }
  }, [formMode]);

  useEffect(() => {
    const storedRequestId = sessionStorage.getItem("requestId");
    if (storedRequestId) {
      setRequestId(storedRequestId);
    }
  }, [isRequestIdSet]);
  const navigate = useNavigate();

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  };

  const [loading, setLoading] = useState(false);

  const handleFinalSubmit = async () => {
    setLoading(true);
    try {
      const ipAddress = await getIpAddress();
      const phoneNumberWithCountryCode = `${formData.countryCode}-${formData.PhoneNumber}`;
      // console.log(phoneNumberWithCountryCode);
      const formDataToSend = new FormData();
      formDataToSend.append("request_id", requestId);
      if (urlParams) {
        formDataToSend.append("main_session_id", urlParams);
      }
      formDataToSend.append("passport_number", formData.passportNumber);
      formDataToSend.append("first_name", formData.FastName);
      formDataToSend.append("last_name", formData.LastName);
      formDataToSend.append("dob", formData.dateOfBirth);
      formDataToSend.append("place_of_birth", formData.placeOfBirth);
      formDataToSend.append("mothers_name", formData.mothersName);
      formDataToSend.append("fathers_name", formData.fathersName);
      formDataToSend.append("passport_issue_date", formData.passportIssueDate);
      formDataToSend.append("passport_expiry_date", formData.passportExpiryDate);
      if (showSubStep4) {
        formDataToSend.append("type_of_supporting_doc", formData.supportingDocType);
      
        if (formData.supportingDocType !== "Not Present") {
          formDataToSend.append("supporting_doc_from", formData.supportingDocFrom);
          formDataToSend.append("supporting_doc_no", formData.supportingDocNo);
      
          if (!formData.unlimited) {
            formDataToSend.append("supporting_doc_expiry_date", formData.supportingDocExpiryDate);
          }
        }
      }
      
      formDataToSend.append("address", formData.address);
      formDataToSend.append("unlimited", formData.unlimited);
      formDataToSend.append("email_address", formData.Email);
      formDataToSend.append("phone", phoneNumberWithCountryCode);
      formDataToSend.append("country", formData.country);
      formDataToSend.append("travel_document", formData.travel_document);
      formDataToSend.append("arrival_date", formData.arrival_date);
      formDataToSend.append("read_and_accept", checkboxChecked);
      formDataToSend.append("privacy_policy", checkboxChecked);
      formDataToSend.append("refund_policy", checkboxChecked);
      formDataToSend.append("status", formData.status);
      if (ipAddress) {
        formDataToSend.append("ip_address", ipAddress);
      }

      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=insert_evisa_application`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "true") {
        if (formMode !== "add" || formMode !== "edit") {
          const mainsessionId = response.data.main_session_id;
          sessionStorage.setItem("mainsessionId", mainsessionId);
        }

        setCurrentStep(2);
        navigate(`/preview`);
        setFormData({
          FastName: "",
          LastName: "",
          Email: "",
          PhoneNumber: "",
          countryCode: "",
          country: "",
          dateOfBirth: "",
          placeOfBirth: "",
          mothersName: "",
          fathersName: "",
          passportNumber: "",
          passportNumberReEnter: "",
          passportIssueDate: "",
          passportExpiryDate: "",
          supportingDocType: "",
          supportingDocFrom: "",
          supportingDocExpiryDate: "",
          supportingDocNo: "",
          address: "",
          read_and_accept: "",
          privacy_policy: "",
          refund_policy: "",
          arrival_date: "",
          status: "",
        });
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      // toast.error('An error occurred while submitting the form.');
    } finally {
      setLoading(false); // Hide the loader
    }
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      const ipAddress = await getIpAddress();
      const phoneNumberWithCountryCode = `${formData.countryCode}-${formData.PhoneNumber}`;
      const formDataToSend = new FormData();
      formDataToSend.append("td_id", tdId);
      formDataToSend.append("request_id", editRequestId);
      formDataToSend.append("main_session_id", urlParams);
      formDataToSend.append("passport_number", formData.passportNumber);
      formDataToSend.append("first_name", formData.FastName);
      formDataToSend.append("last_name", formData.LastName);
      formDataToSend.append("dob", formData.dateOfBirth);
      formDataToSend.append("place_of_birth", formData.placeOfBirth);
      formDataToSend.append("mothers_name", formData.mothersName);
      formDataToSend.append("fathers_name", formData.fathersName);
      formDataToSend.append("passport_issue_date", formData.passportIssueDate);
      formDataToSend.append("passport_expiry_date", formData.passportExpiryDate);
      // if (showSubStep4) {
      //   formDataToSend.append("type_of_supporting_doc", formData.supportingDocType);
      //   formDataToSend.append("supporting_doc_from", formData.supportingDocFrom);
      //   formDataToSend.append("supporting_doc_no", formData.supportingDocNo);
      //   formDataToSend.append("supporting_doc_expiry_date", formData.supportingDocExpiryDate);
      // }
      if (showSubStep4) {
        formDataToSend.append("type_of_supporting_doc", formData.supportingDocType);
      
        if (formData.supportingDocType !== "Not Present") {
          formDataToSend.append("supporting_doc_from", formData.supportingDocFrom);
          formDataToSend.append("supporting_doc_no", formData.supportingDocNo);
      
          if (!formData.unlimited) {
            formDataToSend.append("supporting_doc_expiry_date", formData.supportingDocExpiryDate);
          }
        }
      }
      
      formDataToSend.append("address", formData.address);
      formDataToSend.append("unlimited", formData.unlimited);
      formDataToSend.append("email_address", formData.Email);
      formDataToSend.append("phone", phoneNumberWithCountryCode);
      formDataToSend.append("country", formData.country);
      formDataToSend.append("travel_document", formData.travel_document);
      formDataToSend.append("arrival_date", formData.arrival_date);
      formDataToSend.append("read_and_accept", formData.read_and_accept);
      formDataToSend.append("privacy_policy", formData.privacy_policy);
      formDataToSend.append("refund_policy", formData.refund_policy);
      formDataToSend.append("status", formData.status);
      if (ipAddress) {
        formDataToSend.append("ip_address", ipAddress);
      }
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=update_evisa_application`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "true") {
        setCurrentStep(2);
        navigate(`/preview`);
        setFormData({
          FastName: "",
          LastName: "",
          Email: "",
          PhoneNumber: "",
          countryCode: "",
          country: "",
          dateOfBirth: "",
          placeOfBirth: "",
          mothersName: "",
          fathersName: "",
          passportNumber: "",
          passportNumberReEnter: "",
          passportIssueDate: "",
          passportExpiryDate: "",
          supportingDocType: "",
          supportingDocFrom: "",
          supportingDocExpiryDate: "",
          supportingDocNo: "",
          address: "",
          read_and_accept: "",
          privacy_policy: "",
          refund_policy: "",
          arrival_date: "",
          status: "",
        });
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      // toast.error('An error occurred while submitting the form.');
    } finally {
      setLoading(false); // Hide the loader
    }
  };

  const getStatusClass = (eventKey) => {
    if (currentSubStep === parseInt(eventKey)) {
      return "current-heder";
    } else if (currentSubStep > parseInt(eventKey)) {
      return "completed-heder";
    } else {
      return "incomplete-heder";
    }
  };

  const todaysDate = moment().format("YYYY-MM-DD");

  const [formData, setFormData] = useState({
    FastName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    countryCode: "",
    country: "",
    travel_document: "Ordinary Passport",
    dateOfBirth: "",
    placeOfBirth: "",
    mothersName: "",
    fathersName: "",
    passportNumber: "",
    passportNumberReEnter: "",
    passportIssueDate: "",
    passportExpiryDate: "",
    supportingDocType: "",
    supportingDocFrom: "",
    supportingDocExpiryDate: "",
    supportingDocNo: "",
    address: "",
    arrival_date: todaysDate,
    read_and_accept: "yes",
    privacy_policy: "yes",
    refund_policy: "yes",
    status: "pending",
    step: 1,
    another_applicant: "no",
    email_status: "sent",
    email_status_2: "sent",
    email_status_3: "sent",
    ip_address: "192.168.1.1",
    unlimited: false,
  });

  console.log("formData",formData)
  // const [fees, setFees] = useState('')
  const [singleCountryData, setSingleCountryData] = useState(null);

  const handleData = async (value) => {
    const formDataToSend = new FormData();
    formDataToSend.append("id", value);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=getSingleCountryData`, formDataToSend, {
        headers: {
          Authorization: API.AUTHORIZATION_TOKEN,
        },
      });

      if (response.data.status === "true") {
        setSingleCountryData(response.data.data);
        // setFees(response.data.data.fees)
      } else {
        console.error("Failed to fetch data:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };
  const [supportCountry, setSupportCountry] = useState([]);

  const handleSupportCountry = async (supportingDocType, countryId) => {
    const formDataToSend = new FormData();
    formDataToSend.append("type_of_supporting_doc", supportingDocType);
    formDataToSend.append("country_id", countryId);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=get_supporting_document_from`, formDataToSend, {
        headers: {
          Authorization: API.AUTHORIZATION_TOKEN,
        },
      });

      if (response.data.status === true) {
        setSupportCountry(response.data.data);
        // console.log(response.data.data, "scd");
        setFormData((prevData) => ({
          ...prevData,
          ["supportingDocFrom"]: response.data.data[0],
        }));
      } else {
        console.error("Failed to fetch data:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  const [errors, setErrors] = useState({});
  const [countryQuestionData, setCountryQuestionData] = useState([]);

  const fetchCountryQuestions = async (value) => {
    // console.log("value", value);
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=country_get_questions&country_id=${value}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        if (response.data.data !== null) {
          setCountryQuestionData(response.data.data);
          handleData(value);
          setShowSubStep4(true);
        } else {
          sessionStorage.setItem("country_id", value);
          handleData(value);
          setShowSubStep4(false);
        }
      } else {
        console.error("Failed to fetch data:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  const handleChange = async (e) => {
    const { id, value, type, checked } = e.target;
    if (id === "supportingDocType") {
      if (value !== "") await handleSupportCountry(value, formData.country);
      else setSupportCountry([]);
    }
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [id]: checked ? true : false,
        supportingDocExpiryDate: checked ? "" : prevData.supportingDocExpiryDate,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));

      if (id === "country" && value) {
        fetchCountryQuestions(value);
      }
      if (id === "arrival_date") {
        const selectedDate = moment(value, "YYYY-MM-DD").startOf("day");
        const newStartDate = selectedDate.format("YYYY-MM-DD");

        const newEndDate = selectedDate.clone().add(179, "days").format("YYYY-MM-DD");
        
        setStartDate(newStartDate);
        setEndDate(newEndDate);

        
      }
      if (id === "country") {
        if (value === '14' || value === '18') {
          setFormData((prevData) => ({
            ...prevData,
            supportingDocType: 'Not Present'
          }));
        }
      }
    }
  };

  const today = new Date();
  const sixMonthsFromToday = new Date();
  sixMonthsFromToday.setMonth(today.getMonth() + 6);

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    switch (currentSubStep) {
      case 1:
        if (!formData.FastName) {
          newErrors.FastName = "This field is required";
          isValid = false;
        } else if (!/^[a-zA-Z]+$/.test(formData.FastName)) {
          newErrors.FastName = "Only alphabetic characters are allowed";
          isValid = false;
        }

        if (!formData.LastName) {
          newErrors.LastName = "This field is required";
          isValid = false;
        } else if (!/^[a-zA-Z]+$/.test(formData.LastName)) {
          newErrors.LastName = "Only alphabetic characters are allowed";
          isValid = false;
        }

        if (!formData.Email) {
          newErrors.Email = "This field is required";
          isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.Email)) {
          newErrors.Email = "Invalid email format";
          isValid = false;
        }

        if (!formData.PhoneNumber) {
          newErrors.PhoneNumber = "This field is required";
          isValid = false;
        } else if (!/^\+?[1-9]\d{1,14}$/.test(formData.PhoneNumber)) {
          newErrors.PhoneNumber = "Invalid phone number format";
          isValid = false;
        }
        break;

      case 2:
        if (!formData.country) {
          newErrors.country = "This field is required";
          isValid = false;
        }

        break;

      case showSubStep4 ? 5 : 4:
        if (!formData.mothersName) {
          newErrors.mothersName = "This field is required";
          isValid = false;
        }

        if (!formData.fathersName) {
          newErrors.fathersName = "This field is required";
          isValid = false;
        }

        if (!formData.passportNumber) {
          newErrors.passportNumber = "This field is required";
          isValid = false;
        }
        // else if (formData.passportNumber.length !== 9) {
        //   newErrors.passportNumber = 'Passport number must be exactly 9 characters long';
        //   isValid = false;
        // }

        if (!formData.passportNumberReEnter) {
          newErrors.passportNumberReEnter = "This field not matched with passport number";
          isValid = false;
        } else if (formData.passportNumber !== formData.passportNumberReEnter) {
          newErrors.passportNumberReEnter = "This field not matched with passport number";
          isValid = false;
        }
        if (!formData.dateOfBirth) {
          newErrors.dateOfBirth = "This field is required";
          isValid = false;
        }
        if (!formData.placeOfBirth) {
          newErrors.placeOfBirth = "This field is required";
          isValid = false;
        }
        if (!formData.passportIssueDate) {
          newErrors.passportIssueDate = "This field is required";
          isValid = false;
        }else {
          const dateOfBirth = new Date(formData.dateOfBirth);
          const passportIssueDate = new Date(formData.passportIssueDate);
  
          if (passportIssueDate <= dateOfBirth) {
            newErrors.passportIssueDate = "Passport issue date must be greater than date of birth";
            isValid = false;
          }
        }
        if (!formData.passportExpiryDate) {
          newErrors.passportExpiryDate = "This field is required";
          isValid = false;
        } else {
          const passportExpiryDate = new Date(formData.passportExpiryDate);
          if (passportExpiryDate <= sixMonthsFromToday) {
            newErrors.passportExpiryDate = "You are not eligible for eTA. You must have a valid passport that does not expire within six months of your trip";
            isValid = false;
          }
        }
        if (showSubStep4) {
          if (!formData.supportingDocType) {
            newErrors.supportingDocType = "This field is required";
            isValid = false;
          }

          if (formData.supportingDocType !== "Not Present") {
            if (!formData.supportingDocFrom) {
              newErrors.supportingDocFrom = "This field is required";
              isValid = false;
            }
            if (!formData.supportingDocExpiryDate && !formData.unlimited) {
              newErrors.supportingDocExpiryDate = "This field is required";
              isValid = false;
            }
            if (!formData.supportingDocNo) {
              newErrors.supportingDocNo = "This field is required";
              isValid = false;
            }
          }
        }
        if (!formData.address) {
          newErrors.address = "This field is required";
          isValid = false;
        }
        if (!checkboxChecked) {
          newErrors.checkbox = "You must accept the terms and conditions";
          isValid = false;
        }
        break;

      default:
        break;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleNext = () => {
    if (validateForm()) {
      // console.log("currentSubStep", currentSubStep);
      if (currentSubStep === 4 && !showSubStep4) {
        if (formMode === "edit") {
          handleEdit();
        } else {
          handleFinalSubmit();
        }
      } else if (currentSubStep === 5) {
        if (formMode === "edit") {
          handleEdit();
        } else {
          handleFinalSubmit();
        }
      } else {
        nextSubStep();
      }
    }
  };

  const handlePrev = () => {
    resetErrors();
    prevSubStep();
  };

  const resetErrors = () => {
    setErrors({
      country: "",
      // travel_document: ''
    });
  };

  useEffect(() => {
    if (formMode !== "add" && formMode !== "edit") {
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];
      setCurrentDate(formattedDate);
      setStartDate(formattedDate);
  
      const endDate = new Date(today);
      endDate.setDate(endDate.getDate() + 179);
      setEndDate(endDate.toISOString().split("T")[0]);
    }
  }, [formMode]);
  
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
  
    return `${day} ${month} ${year}`;
  };
  
  const displayId = editRequestId ? editRequestId : requestId;

  const [selectedApplicant, setSelectedApplicant] = useState(null);

  const handleApplicantClick = (applicant) => {
    setSelectedApplicant(applicant);
  };

  const editClick = (requestId, tdId) => {
    navigate(`/application-form?action=${requestId}&mode=edit&tdId=${tdId}`);
    setCurrentStep((prev) => prev - 1);
  };
  return (
    <>
      <Loader show={loading} />
      <div class="container-fluid page-header py-5"></div>

      {editData && editData.length > 0 && (
        <div className="container mt-5">
          <div className="row justify-content-center align-items-center mb-5">
            <div className="col-md-12">
              <h3 className="preview_heading pt-4">Already Added Applicants</h3>
              <div className="card mt-2">
                <div className="card-body">
                  <div className="row mb-3">
                    {editData.map((applicant, index) => (
                      <div className="col-lg-3 col-md-6 col-sm mt-2" key={index} onClick={() => handleApplicantClick(applicant)}>
                        <div className="secure_payment">
                          <div className="row">
                            <div className="col-4 contentdiv">
                              <h6 className="text-white pt-3" style={{ fontSize: "12px", paddingLeft: "12px" }}>
                                &nbsp;&nbsp;Applicant {index + 1}
                              </h6>
                            </div>
                            <div className="col-8 contentdiv">
                              <h6 className="text-white pt-2 text-center mb-0" style={{ fontSize: "13px" }}>
                                &nbsp;&nbsp;{applicant.request_id || "No Request ID"}
                              </h6>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <a style={{ fontSize: "12px" }} className="fw-bold text-decoration-underline" onClick={() => editClick(applicant.request_id, applicant.td_id)}>
                                EDIT
                              </a>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Link to={"/order_final"} style={{ fontSize: "12px" }} className="fw-bold text-decoration-underline">
                                CONTINUE
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div class="container-xxl sub-step-header-container  py-5">
        <div className="container">
          <div className="row bottomline">
            <div className="bottomline text-center">
              <h1>Turkey eVisa Application</h1>
            </div>
            <div className="text-center">
              <h5>{displayId}</h5>
            </div>
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <ProgressBar currentStep={1} />
          <Accordion activeKey={currentSubStep.toString()}>
            <Accordion.Item eventKey="1">
              <Accordion.Header className={`accordion-header ${getStatusClass("1")}`}>
                <FontAwesomeIcon icon={faUser} className="icons" /> Personal Information
              </Accordion.Header>
              <Accordion.Body className="step1">
                <div>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <FormGroup controlId="FastName" className="mt-3">
                          <FormLabel>
                            <span className="required-asterisk">*</span> Given/First Name(s) <span className="required-text">(required)</span>
                          </FormLabel>
                          <div className="d-flex">
                            <FormControl
                              type="text"
                              placeholder="Enter your first name"
                              className={errors.FastName ? "is-invalid" : ""}
                              id="FastName"
                              name="FastName"
                              value={formData.FastName}
                              onChange={handleChange}
                            />
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  Type your name in the box as shown in your passport. If you have a middle/second name, type all the names right after your first name with a space
                                  in between. Only dash (-), dot (), apostrophe (") and comma (,) special characters are allowed.
                                </Tooltip>
                              }
                            >
                              <span className="question_icon">?</span>
                            </OverlayTrigger>
                          </div>
                          {errors.FastName && <p className="invalid-feedback d-block">{errors.FastName}</p>}
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup controlId="LastName" className="mt-3">
                          <FormLabel>
                            <span className="required-asterisk">*</span> Surname(s) <span className="required-text">(required)</span>
                          </FormLabel>
                          <div className="d-flex">
                            <FormControl
                              type="text"
                              placeholder="Enter your last name"
                              className={errors.LastName ? "is-invalid" : ""}
                              name="LastName"
                              value={formData.LastName}
                              onChange={handleChange}
                            />

                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  Please enter your last name, as in your passport. Only dash (-), dot (.), apostrophe (1) and comma () special characters are allowed. If your
                                  surname is not specified on your travel document, you can leave it blank.
                                </Tooltip>
                              }
                            >
                              <span className="question_icon">?</span>
                            </OverlayTrigger>
                          </div>
                          {errors.LastName && <p className="invalid-feedback d-block">{errors.LastName}</p>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <FormGroup controlId="Email" className="mt-3">
                          <FormLabel>
                            <span className="required-asterisk">*</span> Email <span className="required-text">(required)</span>
                          </FormLabel>
                          <div className="d-flex">
                            <FormControl
                              type="email"
                              placeholder="Enter your email"
                              id="Email"
                              name="Email"
                              className={errors.Email ? "is-invalid" : ""}
                              value={formData.Email}
                              onChange={handleChange}
                              disabled={formMode === "add" ? true : false}
                            />
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Please provide an email.</Tooltip>}>
                              <span className="question_icon">?</span>
                            </OverlayTrigger>
                          </div>
                          {errors.Email && <p className="invalid-feedback d-block">{errors.Email}</p>}
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup controlId="PhoneNumber" className="mt-3">
                          <FormLabel>
                            <span className="required-asterisk">*</span> Phone Number <span className="required-text">(required)</span>
                          </FormLabel>
                          <div className="d-flex">
                            <FormControl
                              className="country-codes"
                              as="select"
                              style={{ width: "30%" }}
                              name="CountryCode"
                              id="CountryCode"
                              value={formData.countryCode || ""}
                              onChange={(e) => setFormData({ ...formData, countryCode: e.target.value })}
                              disabled={formMode === "add"}
                            >
                              {options.map((option, index) => (
                                <option key={index} value={option.dialCode}>
                                  ({option.dialCode}) {option.countryName}
                                </option>
                              ))}
                              {/* value={option.callingCode}  {option.label} */}
                            </FormControl>
                            <FormControl
                              type="text"
                              placeholder="Enter your phone number"
                              value={formData.PhoneNumber}
                              className={errors.PhoneNumber ? "is-invalid" : ""}
                              name="PhoneNumber"
                              id="PhoneNumber"
                              onChange={handleChange}
                              disabled={formMode === "add"}
                            />
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip>Please enter your phone number. Only parenthesis, plus and number special characters are allowed.</Tooltip>}
                            >
                              <span className="question_icon phonenumber phonenumber-toolttip">?</span>
                            </OverlayTrigger>
                          </div>
                          {errors.PhoneNumber && <p className="invalid-feedback d-block">{errors.PhoneNumber}</p>}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <div className="button-group">
                    <button onClick={handleNext} className="btn-next">
                      NEXT <FaAngleRight />
                    </button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className={`accordion-header ${getStatusClass("2")}`}>
                <FontAwesomeIcon icon={faMapMarkerAlt} className="icons" /> Country/Region
              </Accordion.Header>
              <Accordion.Body className="step1">
                <div>
                  <div className="form-group form-row d-md-flex mt-3">
                    <div className="col-sm-12 m-1 feedback m-auto">
                      <p className="pt-2 setfontsize">
                        <FaInfoCircle />
                        &nbsp;&nbsp;Please select your country/region of travel document. People with dual nationality should choose the nationality according to the passport to be
                        used for the travel.
                      </p>
                      <p className="setfontsize">
                        <FaInfoCircle />
                        &nbsp;&nbsp;If the country of travel document registered on the e-visa is different than the passport carried; the e-Visa will be invalid.
                      </p>
                      <p className="setfontsize">
                        <FaInfoCircle />
                        &nbsp;&nbsp;By travel document, we mean passports (for the citizens of specific countries). Other travel documents are not acceptable for an e-Visa
                        application.
                      </p>
                    </div>
                  </div>
                  <Form className="my-3">
                    <Row>
                      <Col lg={6}>
                        <FormGroup controlId="country">
                          <FormLabel>
                            <span className="required-asterisk">*</span> Country/Region <span className="required-text">(required)</span>
                          </FormLabel>
                          <div className="d-flex flex-column">
                            <FormControl
                              as="select"
                              id="country"
                              name="country"
                              value={formData.country}
                              onChange={handleChange}
                              className={errors.country ? "is-invalid" : ""}
                              disabled={formMode === "add" || formMode === "edit" ? true : false}
                            >
                              <option value="">Select Country/Region of Your Travel Document</option>
                              {apiData && apiData.length > 0 ? (
                                apiData.map((applicant, index) => <option value={applicant.country_id}>{applicant.country_name} </option>)
                              ) : (
                                <div>No applicants found.</div>
                              )}
                            </FormControl>
                            {errors.country && <p className="invalid-feedback d-block">{errors.country}</p>}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup controlId="travel_document">
                          <FormLabel>
                            <span className="required-asterisk">*</span> Travel Document <span className="required-text">(required)</span>
                          </FormLabel>
                          <div className="d-flex flex-column">
                            <FormControl
                              as="select"
                              id="travel_document"
                              name="travel_document"
                              value={formData.travel_document}
                              onChange={handleChange}
                              disabled={formMode === "add" || formMode === "edit" ? true : false}
                            >
                              <option value="ordinay_passport">Ordinary Passport</option>
                            </FormControl>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <div className="button-group">
                    <button onClick={handlePrev} disabled={currentSubStep === 1} className="btn-previous mx-2">
                      <FaAngleLeft /> BACK
                    </button>
                    <button onClick={handleNext} className="btn-next">
                      NEXT <FaAngleRight />
                    </button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className={`accordion-header ${getStatusClass("3")}`}>
                <FontAwesomeIcon icon={faCalendarDay} className="icons" /> Date Of Arrival
              </Accordion.Header>
              <Accordion.Body className="step3">
                <Row>
                  <Col md={12} className="my-3">
                    <div className="accordion-body">
                      <div className="list-group-item list-group-p">
                        <div data-acc-content="">
                          <div className="my-3">
                            <div className="form-group form-row d-md-flex mt-3">
                              <div className="col-sm-12 col-lg-8 col-md-8 mx-1 my-2">
                                <Form.Label>
                                  <span className="required-asterisk">*</span> Arrival Date in Turkey
                                  <span className="required-text"> (required)</span>
                                </Form.Label>
                                <div className="d-flex pb-1 w-100">
                                <DatePicker
  selected={formData.arrival_date && moment(formData.arrival_date, "YYYY-MM-DD").isValid() ? moment(formData.arrival_date, "YYYY-MM-DD").toDate() : null}
  onChange={(date) => handleChange({ target: { id: "arrival_date", value: date ? moment(date).format("YYYY-MM-DD") : "" } })}
  peekNextMonth
  showMonthDropdown
  showYearDropdown
  dropdownMode="select"
  id="arrival_date_in_türkiye"
  name="arrival_date"
  className="form-control hasDatepicker w-100"
  placeholderText="Select date"
  disabled={formMode === "add"}
  minDate={new Date()}
  maxDate={moment(new Date()).add(3, "months").endOf("month").toDate()}
/>

                                 
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip>
                                        Your arrival date should be within 3 months as of the date of an e-Visa application. (You are advised to apply for an e- Visa at least 48
                                        hours before your departure.)
                                      </Tooltip>
                                    }
                                  >
                                    <span className="question_icon">?</span>
                                  </OverlayTrigger>
                                </div>
                                <Form.Text className="text-danger arrival_date_in_türkiye_err fw-bold fst-italic" style={{ display: "none" }}>
                                  This field is required
                                </Form.Text>
                                <div className="feedback mt-4">
                                  <p>
                                    <FaInfoCircle />
                                    &nbsp;&nbsp;Your e-visa is valid from
                                    <span id="startdatetxt">&nbsp;{formatDate(startDate)}&nbsp;</span>
                                    to
                                    <span id="enddatetxt1">&nbsp;{formatDate(endDate)}&nbsp;</span>
                                    for a total period of 180 days. Your stay cannot exceed
                                    <span id="cannot_exceed">&nbsp;{singleCountryData?.days}</span> days.
                                  </p>
                                </div>
                                <div className="mt-3">
                                  <div className="form-group form-row d-md-flex mt-4">
                                    <div
                                      className="col-sm-3 col-lg-3 col-md-3 border"
                                      style={{ height: "140px", textAlign: "center", boxShadow: "2px 2px 2px 1px rgb(0 0 0 / 20%)" }}
                                    >
                                      <div className="calender_div">
                                        <FaCalendar />
                                      </div>
                                      <div className="my-4">
                                        <span className="startdatetxt">{formatDate(startDate).split(" ")[0]}</span>
                                        <span className="startdatetxt2">{formatDate(startDate).split(" ")[1]}</span>
                                        <span className="startdatetxt3">{formatDate(startDate).split(" ")[2]}</span>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-6 col-md-6 mt-5">
                                      <div className="txt3">
                                        <span className="txt2" id="txt2">
                                          {singleCountryData?.days}
                                        </span>{" "}
                                        Days
                                      </div>
                                      <div className="text1">180 Days</div>
                                      <hr className="hrstyle" />
                                    </div>
                                    <div
                                      className="col-sm-3 col-lg-3 col-md-3 border"
                                      style={{ height: "140px", textAlign: "center", boxShadow: "2px 2px 2px 1px rgb(0 0 0 / 20%)" }}
                                    >
                                      <div className="calender_div1">
                                        <FaCalendar />
                                      </div>
                                      <div className="my-4">
                                        <span className="enddatetxt1">{formatDate(endDate).split(" ")[0]}</span>
                                        <span className="enddatetxt2">{formatDate(endDate).split(" ")[1]}</span>
                                        <span className="enddatetxt3">{formatDate(endDate).split(" ")[2]}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ marginTop: "55px" }}>
                                  <span className="feed_back">
                                    <span className="visa-entry">
                                      Number of Entries: <b id="entry">{singleCountryData?.entry}-Entry</b>
                                    </span>
                                    <span className="visa-fee">
                                      Visa Fee:{" "}
                                      <font style={{ fontSize: "18px" }} id="fees">
                                        {singleCountryData?.fees} USD
                                      </font>
                                    </span>
                                    <span className="ps">
                                      <span className="card_logos">
                                        <img alt="" src={visa} border="0" style={{ width: "39%" }} />
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>

                              <div className="col-sm-12 col-lg-4 col-md-4 mx-1 my-2">
                                <div className="feedback m-auto">
                                  <p className="pt-2" style={{ fontSize: "12px" }}>
                                    <FaInfoCircle />
                                    &nbsp;&nbsp;Please, type the date you plan to enter Turkiye in the related field.
                                  </p>
                                  <p className="pt-2" style={{ fontSize: "12px" }}>
                                    <FaInfoCircle />
                                    &nbsp;&nbsp;The validity period of your e-Visa will begin as of the date you enter Turkiye.
                                  </p>
                                  <p className="pt-2" style={{ fontSize: "12px" }}>
                                    <FaInfoCircle />
                                    &nbsp;&nbsp;Please note that the validity period is different from the period of stay. The period of stay cannot exceed the duration stated on
                                    the left-hand side. If you wish to stay longer, you must apply to your local Police Station for a residency permit.
                                  </p>
                                  <p className="pt-2" style={{ fontSize: "12px" }}>
                                    <FaInfoCircle />
                                    &nbsp;&nbsp;If you exceed the duration stated on the left-hand side on a single entry without having a residency permit, you may be required to
                                    pay fines, deported, or banned from future travel to Turkiye for a period of time.
                                  </p>
                                  <p className="pt-2" style={{ fontSize: "12px" }}>
                                    <FaInfoCircle />
                                    &nbsp;&nbsp;The e-Visa system does not inform you of the number of days you stay in Turkiye. It is your responsibility to make sure that you do
                                    not overstay your visa.
                                  </p>
                                </div>

                                <div className="mt-2 feedback">
                                  <p className="pt-2" style={{ fontSize: "12px" }}>
                                    <FaInfoCircle />
                                    &nbsp;&nbsp;Your arrival date should be within 3 months as of the date of an e-Visa application. (You are advised to apply for an e-Visa at
                                    least 48 hours before your departure.)
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="button-group">
                        <button onClick={prevSubStep} disabled={currentSubStep === 1} className="btn-previous mx-2">
                          <FaAngleLeft /> BACK
                        </button>
                        <button onClick={handleNext} className="btn-next">
                          NEXT <FaAngleRight />
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            {showSubStep4 && (
              <Accordion.Item eventKey="4">
                <Accordion.Header className={`accordion-header ${getStatusClass("4")}`}>
                  <FontAwesomeIcon icon={faListUl} className="icons" /> Prerequisites
                </Accordion.Header>

                <Accordion.Body className="step1">
                  <SubStep4 nextSubStep={nextSubStep} prevSubStep={prevSubStep} currentSubStep={currentSubStep} apiData={countryQuestionData} />
                </Accordion.Body>
              </Accordion.Item>
            )}
            <Accordion.Item eventKey={showSubStep4 ? "5" : "4"}>
              <Accordion.Header className={`accordion-header ${getStatusClass(showSubStep4 ? "5" : "4")}`}>
                <FontAwesomeIcon icon={faFileAlt} className="icons" /> Applicant Details
              </Accordion.Header>
              <Accordion.Body className="step1">
                <div>
                  <div className="list-group-item list-group-p" data-acc-step="">
                    <div data-acc-content="">
                      <div className="form-group form-row d-md-flex mt-3">
                        <div className="col-sm-12 m-1 feedback m-auto">
                          <p id="" className="pt-2 setfontsize">
                            <FaInfoCircle />
                            &nbsp;&nbsp;Please note that the information that you provide must match the information on your passport. Otherwise, your e-Visa will be invalid.
                          </p>
                        </div>
                      </div>
                      <Form>
                        <Row className="mt-3">
                          <Col lg={6} className="mt-3">
                            <FormGroup controlId="dateOfBirth">
                              <FormLabel>
                                <span className="required-asterisk">*</span> Date of Birth <span className="required-text">(required)</span>
                              </FormLabel>
                              <div className="d-flex">
                          <DatePicker
  selected={formData.dateOfBirth ? moment(formData.dateOfBirth, "YYYY-MM-DD").isValid() ? moment(formData.dateOfBirth, "YYYY-MM-DD").toDate() : null : null}
  onChange={(date) => handleChange({ target: { id: "dateOfBirth", value: date ? moment(date).format("YYYY-MM-DD") : "" } })}
  peekNextMonth
  showMonthDropdown
  showYearDropdown
  dropdownMode="select"
  className={`${errors.dateOfBirth ? "is-invalid" : ""} form-control hasDatepicker w-100`}
  placeholderText="Enter your date of birth"
  maxDate={new Date(todaydate)}
/>

                                {/* <FormControl
                                  type="date"
                                  placeholder="Enter your date of birth"
                                  className={errors.dateOfBirth ? 'is-invalid' : ''}
                                  value={formData.dateOfBirth}
                                  onChange={handleChange}
                                  max={todaydate}
                                /> */}
                                <OverlayTrigger placement="bottom" overlay={<Tooltip>Enter your date of birth in the dd.mm.yyyy format, as in your passport</Tooltip>}>
                                  <span className="question_icon">?</span>
                                </OverlayTrigger>
                              </div>
                              {errors.dateOfBirth && <div className="invalid-feedback d-block">{errors.dateOfBirth}</div>}
                            </FormGroup>
                          </Col>
                          <Col lg={6} className="mt-3">
                            <FormGroup controlId="placeOfBirth">
                              <FormLabel>
                                <span className="required-asterisk">*</span> Place of Birth <span className="required-text">(required)</span>
                              </FormLabel>
                              <div className="d-flex">
                                <FormControl
                                  type="text"
                                  placeholder="Enter your place of birth"
                                  value={formData.placeOfBirth}
                                  className={errors.placeOfBirth ? "is-invalid" : ""}
                                  onChange={handleChange}
                                />
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Enter your place of birth, as in your passport. Only dash (-), dot (.), apostrophe (') and comma (,) special characters are allowed.
                                    </Tooltip>
                                  }
                                >
                                  <span className="question_icon">?</span>
                                </OverlayTrigger>
                              </div>
                              {errors.placeOfBirth && <div className="invalid-feedback d-block">{errors.placeOfBirth}</div>}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={6} className="mt-3">
                            <FormGroup controlId="mothersName">
                              <FormLabel>
                                <span className="required-asterisk">*</span> Mother's Name <span className="required-text">(required)</span>
                              </FormLabel>
                              <div className="d-flex">
                                <FormControl
                                  type="text"
                                  placeholder="Enter your mother's name"
                                  value={formData.mothersName}
                                  onChange={handleChange}
                                  className={errors.mothersName ? "is-invalid" : ""}
                                />
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Type your mother's name (optional). If she has a middle/second name, type all the names right after her first name with a space in between.
                                      Only dash (-), dot (.), apostrophe (') and comma (,) special characters are allowed
                                    </Tooltip>
                                  }
                                >
                                  <span className="question_icon">?</span>
                                </OverlayTrigger>
                              </div>
                              {errors.mothersName && <div className="invalid-feedback d-block">{errors.mothersName}</div>}
                            </FormGroup>
                          </Col>
                          <Col lg={6} className="mt-3">
                            <FormGroup controlId="fathersName">
                              <FormLabel>
                                <span className="required-asterisk">*</span> Father's Name <span className="required-text">(required)</span>
                              </FormLabel>
                              <div className="d-flex">
                                <FormControl
                                  type="text"
                                  placeholder="Enter your father's name"
                                  className={errors.fathersName ? "is-invalid" : ""}
                                  value={formData.fathersName}
                                  onChange={handleChange}
                                />
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Type your father's name (optional). If he has a middle/second name, type all the names right after his first name with a space in between.
                                      Only dash (-), dot (.), apostrophe (') and comma (,) special characters are allowed
                                    </Tooltip>
                                  }
                                >
                                  <span className="question_icon">?</span>
                                </OverlayTrigger>
                              </div>
                              {errors.fathersName && <div className="invalid-feedback d-block">{errors.fathersName}</div>}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={6} className="mt-3">
                            <FormGroup controlId="passportNumber">
                              <FormLabel>
                                <span className="required-asterisk">*</span> Passport Number <span className="required-text">(required)</span>
                              </FormLabel>
                              <div className="d-flex">
                                <FormControl
                                  type="text"
                                  placeholder="Enter your passport number"
                                  value={formData.passportNumber}
                                  className={errors.passportNumber ? "is-invalid" : ""}
                                  onChange={handleChange}
                                />
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Please type your travel document number as it appears on your travel document. Special characters and white space are not allowed.
                                    </Tooltip>
                                  }
                                >
                                  <span className="question_icon">?</span>
                                </OverlayTrigger>
                              </div>
                              {errors.passportNumber && <div className="invalid-feedback d-block">{errors.passportNumber}</div>}
                            </FormGroup>
                          </Col>
                          <Col lg={6} className="mt-3">
                            <FormGroup controlId="passportNumberReEnter">
                              <FormLabel>
                                <span className="required-asterisk">*</span> Passport Number (Re-enter) <span className="required-text">(required)</span>
                              </FormLabel>
                              <div className="d-flex">
                                <FormControl
                                  type="text"
                                  placeholder="Re-enter your passport number"
                                  value={formData.passportNumberReEnter}
                                  className={errors.passportNumberReEnter ? "is-invalid" : ""}
                                  onChange={handleChange}
                                />
                                <OverlayTrigger placement="bottom" overlay={<Tooltip>Please re-enter passport number</Tooltip>}>
                                  <span className="question_icon">?</span>
                                </OverlayTrigger>
                              </div>
                              {errors.passportNumberReEnter && <div className="invalid-feedback d-block">{errors.passportNumberReEnter}</div>}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={6} className="mt-3">
                            <FormGroup controlId="passportIssueDate">
                              <FormLabel>
                                <span className="required-asterisk">*</span> Passport Issue Date <span className="required-text">(required)</span>
                              </FormLabel>
                              <div className="d-flex">
                              <DatePicker
  selected={formData.passportIssueDate && moment(formData.passportIssueDate, "YYYY-MM-DD").isValid() ? moment(formData.passportIssueDate, "YYYY-MM-DD").toDate() : null}
  onChange={(date) => handleChange({ target: { id: "passportIssueDate", value: date ? moment(date).format("YYYY-MM-DD") : "" } })}
  peekNextMonth
  showMonthDropdown
  showYearDropdown
  dropdownMode="select"
  className={`${errors.passportIssueDate ? "is-invalid" : ""} form-control hasDatepicker w-100`}
  placeholderText="Enter passport issue date"
  maxDate={new Date(todaydate)}
/>

                                {/* <FormControl
                                  type="date"
                                  placeholder="Enter passport issue date"
                                  value={formData.passportIssueDate}
                                  className={errors.passportIssueDate ? 'is-invalid' : ''}
                                  onChange={handleChange}
                                  max={todaydate}
                                /> */}
                                <OverlayTrigger placement="bottom" overlay={<Tooltip>Please select a passport issue date</Tooltip>}>
                                  <span className="question_icon">?</span>
                                </OverlayTrigger>
                              </div>
                              {errors.passportIssueDate && <div className="invalid-feedback d-block">{errors.passportIssueDate}</div>}
                            </FormGroup>
                          </Col>
                          <Col lg={6} className="mt-3">
                            <FormGroup controlId="passportExpiryDate">
                              <FormLabel>
                                <span className="required-asterisk">*</span> Passport Expiry Date <span className="required-text">(required)</span>
                              </FormLabel>
                              <div className="d-flex">
                              <DatePicker
  selected={formData.passportExpiryDate && moment(formData.passportExpiryDate, "YYYY-MM-DD").isValid() ? moment(formData.passportExpiryDate, "YYYY-MM-DD").toDate() : null}
  onChange={(date) => handleChange({ target: { id: "passportExpiryDate", value: date ? moment(date).format("YYYY-MM-DD") : "" } })}
  peekNextMonth
  showMonthDropdown
  showYearDropdown
  dropdownMode="select"
  className={`${errors.passportExpiryDate ? "is-invalid" : ""} form-control hasDatepicker w-100`}
  placeholderText="Enter passport expiry date"
  minDate={new Date(todaydate)} // Set minimum date to today
/>

                                {/* <FormControl
                                  type="date"
                                  placeholder="Enter passport expiry date"
                                  value={formData.passportExpiryDate}
                                  className={errors.passportExpiryDate ? 'is-invalid' : ''}
                                  onChange={handleChange}
                                  min={today}
                                /> */}
                                <OverlayTrigger placement="bottom" overlay={<Tooltip>Please select a passport expiry date</Tooltip>}>
                                  <span className="question_icon">?</span>
                                </OverlayTrigger>
                              </div>
                              {errors.passportExpiryDate && <div className="invalid-feedback d-block">{errors.passportExpiryDate}</div>}
                            </FormGroup>
                          </Col>
                        </Row>
                        {showSubStep4 && (
                          <Row className="mt-3">
                            <Col lg={6} className="mt-3">
                              <FormGroup controlId="supportingDocType">
                                <FormLabel>
                                  <span className="required-asterisk">*</span> Type of Supporting Doc <span className="required-text">(required)</span>
                                </FormLabel>
                                <div className="d-flex">
                                  <FormControl
                                    as="select"
                                    name="supportingDocType"
                                    value={formData.supportingDocType || (formData.country === '18' || formData.country === '14' ? 'Not Present' : '')}
                                    // value={formData.supportingDocType}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    className={errors.supportingDocType ? "is-invalid" : ""}
                                  >
                                    <option value="">Select an option</option>
                                    <option value="visa">Visa</option>
                                    <option value="residence_permit">Residence Permit</option>
                                    {formData.country === "18" || formData.country === "14" ? <option value="Not Present">Not Present</option> : null}
                                  </FormControl>

                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Please provide a valid visa or residence permit from one of the Schengen Countries, USA, UK, or Ireland.</Tooltip>}
                                  >
                                    <span className="question_icon">?</span>
                                  </OverlayTrigger>
                                </div>
                                {errors.supportingDocType && <div className="invalid-feedback d-block">{errors.supportingDocType}</div>}
                              </FormGroup>
                            </Col>
                            {formData.supportingDocType !== "Not Present" && (
                              <>
                                <Col lg={6} className="mt-3">
                                  <FormGroup controlId="supportingDocFrom">
                                    <FormLabel>
                                      <span className="required-asterisk">*</span> Supporting Doc. From <span className="required-text">(required)</span>
                                    </FormLabel>
                                    <div className="d-flex">
                                      {/* {console.log(`errors.supportingDocFrom ${errors.supportingDocFrom}`)} */}
                                      <FormControl
                                        as="select"
                                        name="supportingDocFrom"
                                        id="supportingDocFrom"
                                        className={errors.supportingDocFrom ? "is-invalid" : ""}
                                        // className={errors.supportingDocType ? 'is-invalid' : ''}
                                        value={formData.supportingDocFrom || supportCountry[0]}
                                        // value={formData.supportingDocFrom || (supportCountry.length > 0 ? supportCountry[0] : '')}
                                        onChange={handleChange}
                                      >
                                        {supportCountry.map((option, index) => (
                                          <option key={index} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </FormControl>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip>Please provide a valid visa or residence permit from one of the Schengen Countries, USA, UK, or Ireland.</Tooltip>}
                                      >
                                        <span className="question_icon">?</span>
                                      </OverlayTrigger>
                                    </div>
                                    {errors.supportingDocType && <div className="invalid-feedback d-block">{errors.supportingDocType}</div>}
                                  </FormGroup>
                                </Col>
                                <Col lg={6} className="mt-3">
                                  <FormGroup controlId="supportingDocNo">
                                    <FormLabel>
                                      <span className="required-asterisk">*</span> Supporting Doc. No. <span className="required-text">(required)</span>
                                    </FormLabel>
                                    <div className="d-flex">
                                      <FormControl
                                        type="text"
                                        name="supportingDocNo"
                                        id="supportingDocNo"
                                        className={errors.supportingDocNo ? "is-invalid" : ""}
                                        placeholder="Enter supporting document number"
                                        value={formData.supportingDocNo}
                                        onChange={handleChange}
                                      />
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip>
                                            Please type your supporting document number as it appears on the document. Special characters and white space are not allowed.
                                          </Tooltip>
                                        }
                                      >
                                        <span className="question_icon">?</span>
                                      </OverlayTrigger>
                                    </div>
                                    {errors.supportingDocNo && <div className="invalid-feedback d-block">{errors.supportingDocNo}</div>}
                                  </FormGroup>
                                </Col>

                                <Col lg={6} className="mt-3">
                                  <FormGroup controlId="supportingDocExpiryDate">
                                    <FormLabel>
                                      <span className="required-asterisk">*</span> Supp. Doc. Expiry Date <span className="required-text">(required)</span>
                                    </FormLabel>
                                    <div className="d-flex">
                                      <DatePicker
                                        // dateFormat="dd/MM/YYYY"
                                        // selected={formData.supportingDocExpiryDate}
                                        selected={formData.supportingDocExpiryDate ? moment(formData.supportingDocExpiryDate, "YYYY-MM-DD").toDate() : null} // 'DD/MM/YYYY'
                                        onChange={(date) => handleChange({ target: { id: "supportingDocExpiryDate", value: moment(date).format("YYYY-MM-DD") } })}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className={`${errors.supportingDocExpiryDate ? "is-invalid" : ""} form-control hasDatepicker w-100`}
                                        placeholderText="Enter expiry date"
                                        minDate={new Date(todaydate)} // Set minimum date to today
                                        disabled={formData.unlimited}
                                      />
                                      {/* <FormControl
                                      type="text"
                                      name="supportingDocExpiryDate"
                                      id="supportingDocExpiryDate"
                                      className={errors.supportingDocExpiryDate ? 'is-invalid' : ''}
                                      placeholder="Enter expiry date"
                                      value={formData.supportingDocExpiryDate}
                                      onChange={handleChange}
                                      disabled={formData.unlimited}
                                    /> */}
                                      <FormCheck
                                        type="checkbox"
                                        name="unlimited"
                                        id="unlimited"
                                        label="Unlimited"
                                        value="unlimited"
                                        checked={formData.unlimited}
                                        className="mt-2"
                                        onChange={handleChange}
                                      />
                                      <OverlayTrigger placement="bottom" overlay={<Tooltip>Please select a supporting doc. expiry date</Tooltip>}>
                                        <span className="question_icon">?</span>
                                      </OverlayTrigger>
                                    </div>
                                    {errors.supportingDocExpiryDate && <div className="invalid-feedback d-block">{errors.supportingDocExpiryDate}</div>}
                                  </FormGroup>
                                </Col>
                              </>
                            )}
                            {/* </Row>
                        <Row className='mt-3'> */}
                          </Row>
                        )}
                        <Row className="mt-3">
                          <Col lg={6}>
                            <FormGroup controlId="address">
                              <FormLabel>
                                <span className="required-asterisk">*</span> Address <span className="required-text">(required)</span>
                              </FormLabel>
                              <div className="d-flex">
                                <FormControl
                                  as="textarea"
                                  className={errors.address ? "is-invalid" : ""}
                                  placeholder="Enter your address"
                                  value={formData.address}
                                  onChange={handleChange}
                                />
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Enter your permanent address of residence. Only dot (.), comma (,), colon (:), semi-colon (;), slash (/), backslash (\), underscore (_),
                                      numbers and special characters are allowed.
                                    </Tooltip>
                                  }
                                >
                                  <span className="question_icon">?</span>
                                </OverlayTrigger>
                              </div>
                              {errors.address && <div className="invalid-feedback d-block">{errors.address}</div>}
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="form-group form-row d-md-flex mt-4">
                          <div className="col-sm-7 m-1">
                            <Form.Check
                              type="checkbox"
                              id="checkbox"
                              label="I have read and accept the Terms and conditions, Privacy Policy and Refund Policy of the Turkey Entry Commitment and Information Form."
                              name="question"
                              value="yes"
                              checked={checkboxChecked === true}
                              onChange={handleCheckboxChange}
                            />
                            {errors.checkbox && <div className="invalid-feedback d-block">{errors.checkbox}</div>}
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div className="button-group">
                    {currentSubStep > 1 && (
                      <button onClick={prevSubStep} className="btn-previous mx-2">
                        <FaAngleLeft /> BACK
                      </button>
                    )}
                    <button
                      className="btn-next"
                      onClick={handleNext}

                      // onClick={handleFinalSubmit}
                    >
                      NEXT <FaAngleRight />
                    </button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      {showPopup && <DetailModal showPopup={showPopup} onHide={handleClosePopup} />}
    </>
  );
};

export default Step1;
