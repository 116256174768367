import logo from "./logo.svg";
import "./App.css";
import MultistepForm from "./form/multistepForm";
import Navbar from "./header/header";
import RoutesPage from "./routes/routes";
import Footer from "./footer/footer";
import { Link } from "react-router-dom";
import AddedApplicannt from "./form/mainSteps/addedApplicant";
import CustomDatePicker from "./components/flatPickker";

function App() {
  return (
    <>
        <Navbar />
    {/* <AddedApplicannt/>  */}
{/* <CustomDatePicker/> */}
        <RoutesPage />
        <Footer />
    </>
  );
}

export default App;
