import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import MultiStepForm from "../form/multistepForm";
import Step1 from "../form/mainSteps/step1";
import Step2 from "../form/mainSteps/step2";
import Step3 from "../form/mainSteps/step3";
import ThankYou from "../thankyou/ThankYou";

function RoutesPage() {
  const mainsessionId = sessionStorage.getItem("mainsessionId");
  const hasSessionId = !!sessionStorage.getItem("mainsessionId");
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/application-form" />} />
        <Route exact path="/application-form" element={<Step1 />} />
        <Route exact path="/preview" element={<Step2 />} />
        <Route
          exact
          path="/order_final"
          element={
            <ProtectedRoute step="Final">
              <Step3 />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/thankyou"
          element={
            <ProtectedRoute step="Thankyou">
              <ThankYou />
            </ProtectedRoute>
          }
        />
        {/* <Route path="/order_final" element={hasSessionId ? <Step3 /> : <Navigate to="/appliation-form" />} /> */}
        {/* <Route path="/" element={ !sessionStorage.getItem("mainsessionId") ? <Navigate to="/appliation-form" /> : <Outlet /> }>
          <Route exact path="/order_final" element={<Step3 />} />
        </Route> */}
      </Routes>
    </>
  );
}
export default RoutesPage;

const ProtectedRoute = ({ children, step }) => {
  if (step == "Final") {
    // const hasSessionId = !!sessionStorage.getItem("mainsessionId");
    let hasSessionId = true;
    const sessionId = sessionStorage.getItem("mainsessionId");

    if (sessionId === "false" || sessionId === null || sessionId === "") hasSessionId = false;

    return hasSessionId ? children : <Navigate to="/application-form" />;
  }
  if (step == "Thankyou") {
    let hasSessionPayment = true;
    const sessionPayment = sessionStorage.getItem("payment");
    if (sessionPayment === "false" || sessionPayment === null || sessionPayment === "") hasSessionPayment = false;

    return hasSessionPayment ? children : <Navigate to="/application-form" />;
  }
};
