import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";

import axios from "axios";
import securepayment from "../../src/auth/securepayment.png";
import API from "../utils/utils";
import Loader from "../components/Loader";

const Summary = () => {
  useEffect(() => {
    fetchSummaryData();
    fetchFeesData();
  }, []);
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [feesData, setFeesData] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const displayedData = showMore ? summaryData : summaryData.slice(0, 2);

  const fetchSummaryData = async () => {
    setLoading(true);
    try {
      const mainsessionId = sessionStorage.getItem("mainsessionId");
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.Get_Summary}${mainsessionId}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });
      if (response.data.status == "true") {
        // console.log(response.data.data);
        setSummaryData(response.data.data);
      } else {
        console.error("Fetch summary error: ", response.data.message);
      }
    } catch (error) {
      console.error("summary error: ", error);
    }
    setLoading(false);
  };
  const fetchFeesData = async () => {
    setLoading(true);
    try {
      const mainsessionId = sessionStorage.getItem("mainsessionId");
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.Get_Fees}${mainsessionId}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });
      if (response.data.status == "true") {
        // console.log(response.data.data[0]);
        setFeesData(response.data.data[0]);
      } else {
        console.error("Fetch summary fees error: ", response.data.message);
      }
    } catch (error) {
      console.error("summary fees error: ", error);
    }
    setLoading(false);
  };
  return (
    <>
      <Loader show={loading} />
      <div className="card">
        <div className="border card-body pt-4 sidebar">
          <img src={securepayment} width="290px" height="90" alt="Secure Payment" className="secure-payment" />
          <div className="pt-4 order_summary">
            <h4 className="card-title">ORDER SUMMARY</h4>
            <div id="all_applicants">
              {displayedData &&
                displayedData.length > 0 &&
                displayedData.map((summary, i) => (
                  <div key={i} className="mb-2 d-flex">
                    <FaCheck className="text-success mt-2"/>
                    <div className="ms-1">
                      <span className="summary-data">
                        <i className="fa fa-check benefits_icons"></i>Full Name: {summary.first_name} {summary.last_name}
                      </span>
                      <br />
                      <span className="summary-data">Email Address: {summary.email_address}</span>
                      <br />
                      <span className="summary-data">Document Number: {summary.td_id}</span>
                      {/* <br /> */}
                    </div>
                  </div>
                ))}

              {/* <br /> */}
              {summaryData.length > 2 && (
                <button className="btn btn-next btn-sm text-white" onClick={() => setShowMore(!showMore)}>
                  {showMore ? "Show Less" : "Check All Applicants"}
                </button>
              )}
            </div>
          </div>
          <div class="pt-4 order_summary">
            <h4 className="card-title">Processing Time</h4>
            <p style={{ fontSize: "12px", color: "black" }}>Standard Processing time 1-2 hrs.,in some cases it may one business day.</p>
          </div>

          <div class="pt-4 order_summary">
            <h4 className="card-title">Email</h4>
            <p style={{ fontSize: "12px", color: "black" }}>Please check your junk/spam folder in case if you didn’t find email in your inbox.</p>
          </div>

          <div class="pt-4 payment">
            <h4 className="card-title-payment">PAYMENT</h4>
            {feesData && (
              <>
                <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>
                  {feesData.count} eVisa Turkey – (${feesData.fees} USD)
                </p>
                <p style={{ fontSize: "12px", color: "black" }}>Total - ${feesData.total_fees} USD</p>
              </>
            )}

            <p className="mb-0" style={{ fontSize: "12px", color: "black" }}>
              This Transaction will appear on your statement as <br />
              *TURKEY EVISA.
            </p>
            <p class="important_note">
              <b>Important note:</b> When you travel to Turkey, you will need to bring the passport you used to apply for your e-Visa, as the e-Visa is electronically linked to it.
              If you obtain a new passport, you will need to apply for a new e-Visa.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
