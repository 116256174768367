import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FaAngleLeft, FaAngleRight, FaInfoCircle } from 'react-icons/fa';
import API from '../../utils/utils';
import axios from 'axios';

const SubStep4 = ({ nextSubStep, prevSubStep, currentSubStep,apiData }) => {
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState('');

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [id]: checked
    }));
  };

  const validateForm = () => {
    // Perform validation checks
    const allChecked = Object.values(formValues).every(value => value === true);
    if (!allChecked) {
      setErrors('Please confirm that you meet all the criteria by clicking the boxes.');
      return false;
    }
    setErrors('');
    return true;
  };

  const handleNextClick = () => {
    if (validateForm()) {
      nextSubStep();
    }
  };

  // const [apiData, setApiData] = useState([]);
  const countryId = sessionStorage.country_id;

  // const handleData = async () => {
  //   try {
  //     const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=country_get_questions&country_id=${countryId}`, {
  //       headers: {
  //         Authorization: `${API.AUTHORIZATION_TOKEN}`
  //       }
  //     });

  //     if (response.data.status === "success") {
  //       // clg
  //       setApiData(response.data.data);

  //       const initialValues = response.data.data.reduce((acc, item) => {
  //         acc[item.que_id] = true;
  //         return acc;
  //       }, {});
  //       setFormValues(initialValues);
  //     } else {
  //       console.error('Failed to fetch data:', response.data.message);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching country data:', error);
  //   }
  // };

  useEffect(() => {
    
    const initialValues = apiData.reduce((acc, item) => {
              acc[item.que_id] = true;
              return acc;
            }, {});
            setFormValues(initialValues);
            // console.log("initialValues", initialValues);
            
  }, [apiData]);

  return (
    <div>
      <div className="list-group-item list-group-p" data-acc-step="">
        <div data-acc-content="">
          <div className="my-3">
            <div className="row">
              <div className="col-sm-6 col-lg-6 col-md-6">
                <Form>
                  <Form.Group>
                    {apiData.map((item) => (
                      <Form.Check
                        key={item.que_id}
                        type="checkbox"
                        id={item.que_id}
                        label={item.questions}
                        checked={formValues[item.que_id] || false}
                        onChange={handleCheckboxChange}
                      />
                    ))}
                  </Form.Group>
                  {errors && (
                    <div className="invalid-feedback d-block">
                      {errors}
                    </div>
                  )}
                </Form>
              </div>
              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="feedback">
                  <p style={{ fontSize: '12px' }}>
                    <FaInfoCircle />&nbsp;&nbsp;You are entitled to obtain e-Visa on condition that you meet the criteria indicated on the left side.
                  </p>
                  <p style={{ fontSize: '12px' }}>
                    <FaInfoCircle />&nbsp;&nbsp;Your e-Visa will be valid on the condition that you meet these criteria.
                  </p>
                  <p style={{ fontSize: '12px' }}>
                    <FaInfoCircle />&nbsp;&nbsp;If you do not meet these criteria, your e-Visa will be invalid regardless of the fact that you obtained it.
                  </p>
                  <p style={{ fontSize: '12px' }}>
                    <FaInfoCircle />&nbsp;&nbsp;To confirm that you meet the criteria, click the boxes next to them.
                  </p>
                </div>
                <div className="feedback mt-2">
                  <p style={{ fontSize: '12px' }}>
                    &nbsp;&nbsp;You must meet all the requirements listed below in order to obtain an e-Visa. &nbsp;&nbsp;Please confirm that you meet these criteria by clicking the boxes next to them. If &nbsp;&nbsp;you do not meet any of these requirements and you proceed with your &nbsp;&nbsp;&nbsp;application, your e-Visa will be invalid.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button-group">
        <Button onClick={prevSubStep} disabled={currentSubStep === 1} className='btn-previous mx-2'>
          <FaAngleLeft /> BACK
        </Button>
        <Button onClick={handleNextClick} className='btn-next'>
          NEXT <FaAngleRight />
        </Button>
      </div>
    </div>
  );
};

export default SubStep4;
