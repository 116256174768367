import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container-fluid bg-dark text-light footer pt-5  wow fadeIn">
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-12 text-center mb-3 mb-md-0">
                                © <a className="footer-border" href="#">TURKEY EVISA Services</a> , All Rights Reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      {/* <div className="container-fluid bg-dark text-light footer pt-5 wow fadeIn">
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-12 text-center mb-3 mb-md-0">
                ©{" "}
                <a className="footer-border" href="#">
                  TURKEY EVISA Services
                </a>
                , All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default Footer;
