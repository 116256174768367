import React , { useState } from "react";
import { Modal, Button } from "react-bootstrap";


const DetailModal = ({showPopup, onHide}) => {

  return (
    <Modal show={showPopup} onHide={onHide} className='modal-lg'>
    <Modal.Header closeButton>
      <h5 class="modal-title">Terms and Conditions,Privacy Policy and Refund Policy</h5>
    </Modal.Header>
    <Modal.Body className="modal-body-scrollable">
      <h2>Terms and Conditions</h2>
      <div class="row">
        <div class="col-md-12">
          <div class="content1">
            <p class="head">1. ABOUT THIS WEBSITE</p>
            <p>This is a website operated by “TRAVEL AND VISA SERVICES PTY LTD (ABN: 78661260626)” under the domain name www.turkey-evisa-services.org (hereinafter referred to as the 'website'). </p>
            <p class="head">2. ABOUT THESE TERMS OF USE</p>
            <p>These website terms of use (hereinafter the 'terms of use') govern your access to and use of the website. You should read these terms of use carefully before using this website.</p>

            <p class="head">3. YOUR ACCESS/USE IMPLIES AGREEMENT</p>
            <p>The website is available for your use only on condition that you agree to these terms of use. By accessing/using the website, you are signifying your agreement to be bound by these terms of use. If you do not agree to these terms of use, you must not use our website. It is recommended that you print a copy of these terms for future reference. </p>

            <p>Please note: the services advertised on the website cannot be booked by individuals under the age of 18. You must be at least 18 years of age at the time of booking and have the legal capacity and authority to book the services. </p>
            <p class="head">4. CERTAIN OTHER ACTIONS IMPLY AGREEMENT TO ADDITIONAL TERMS</p>
            <p>In addition to these terms of use, there may also be specific terms ('additional terms') which govern your use of, and access to, particular sections of the website. Since you are also bound by these additional terms, you should review them wherever they are offered to you. Certain actions, payment or agreement on your part may mean that such services are additional to the normal terms and are subject to a separate agreement. When this happens, you shall be offered additional terms through a hyperlink associated with these additional services. </p>

            <p>These terms of use refer to the following additional terms, which also apply to your use of the website: </p>
            <ul>
              <li>
                <p>Our <a href="https://turkey-evisa-services.org/privacy.php">Privacy Notice</a></p>
              </li>
            </ul>
            <p>If you are a business user and you have an agreement/contract in place with “Turkey Evisa”, the terms of that agreement/contract will apply. </p>
            <p>5. LIMITED LICENCE</p>
            <p>“Turkey Evisa” is the owner or the licensee of all intellectual property rights in and on the website, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved. </p>
            <p>“Turkey Evisa” grants you a limited, personal, non-transferable, non-sub-licensable, revocable license to:     </p>
            <p>(a) access and use the website, content and services only; and </p>
            <p>(b) access and use the website, content and services in the manner presented by “Turkey Evisa” only. </p>
            <p>None of the content and/or information may be reverse-engineered, modified, reproduced, republished, translated into any other language or computer language, re-transmitted in any form or by any means, resold or redistributed without the prior written consent of “Turkey Evisa”. You may not make, sell, offer for sale, modify, reproduce, display, publicly perform, import, distribute, retransmit or otherwise use the content in any way, unless expressly permitted to do so by “Turkey Evisa” </p>

          </div>
        </div>
      </div>
      <h2>Privacy Policy</h2>
      <div class="row">
        <div class="col-md-12">
          <div class="content1">
            <p class="head">1. Introduction</p>
            <p>Welcome to the privacy notice for the “Turkey Evisa</p>

            <p>“Turkey Evisa” respects your privacy and is committed to protecting your personal data. This privacy notice will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) or when you otherwise provide personal data to us via other means (such as over the telephone) (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>

            <p class="head">2. Companies and websites within scope</p>
            <p>The following companies and websites are within the scope of this privacy notice. “Turkey Evisa” </p>

            <a href="https://turkey-evisa-services.org" target="_blank">www.turkey-evisa-services.org</a>

            <p class="head">3. Important information and who we are</p>
            <p class="strong">Purpose of this privacy notice</p>

            <p>This privacy notice aims to give you information on how the “Turkey Evisa” collects, manages and processes your personal data, including any data you may provide through this website or via any other means (such as over the telephone) when you purchase travel arrangements through us or otherwise provide personal data to us. This website is not intended for children and the only circumstances in which we collect data relating to children is where you make a booking of arrangements and you have children in your party. </p>

            <p>It is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice supplements the other notices and is not intended to override them. </p>
            <p class="strong">Data Controller/Data Processor </p>
            <p>“Turkey Evisa” can be considered both a controller and a processor and remains responsible for your personal data (collectively referred to as ““Turkey Evisa” ", "we", "us" or "our" in this privacy notice). </p>

            <p class="strong">Data Controller/Data Processor</p>
            <p>Travel &amp; Visa Services P/L can be considered both a controller and a processor and remains responsible for your personal data (collectively referred to as “Travel &amp; Visa Services P/L ", "we", "us" or "our" in this privacy notice).</p>

            <p>When we work with agents to process itinerary requests, travel bookings or travel services on behalf of their clients, we are be considered the data processor. This is because we act on the instruction of the agent and process their client’s personal data in order to provide services that the agent requests on behalf of their client. </p>

            <p>When we work with other companies, such as our GetYourGuide, Trip.com, Wayaway and Visitors Coverage Travel Insurance , we are considered to be an affiliate, as we are able to determine and manage the means and purpose of processing a guest’s personal data. </p>

            <p>We have appointed a data protection compliance manager who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the data protection compliance manager using the details set out below.</p>
            <p class="strong">Right to complain </p>


            <p>You have the right to make a complaint at any time. There are many data protection supervisory bodies around the world and we would suggest that you make any complaints to the relevant supervisory body (details above). We would, however, appreciate the opportunity to deal with your concerns before you approach a supervisory body, so please contact us in the first instance. </p>

            <p class="strong">Changes to the privacy notice and your duty to inform us of changes </p>


            <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if any of the details you provide to us should change, during the course of your relationship with us. </p>

            <p class="strong">Third-party links </p>

            <p>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit. </p>

            <p class="head">4. The data we collect about you or your clients</p>
            <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). </p>

            <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows: </p>
            <p>(A) Identity Data </p>
            <p>This includes data relating specifically to your identity, such as your first name, maiden name, last name or similar identifier, marital status, title, date of birth and gender </p>

            <p>(B) Contact Data </p>
            <p>This includes data relating to how you may be contacted, such as your home address, business address, email address and telephone numbers. </p>

            <p>(C) Financial Data</p>
            <p>This includes data relating to your means and methods of payment, such as your bank account and payment card details. We don't retain or store credit card details unless as authorised to retain as a security deposit and we will obtain your express authority to store these for this purpose. </p>

            <p>(D) Transaction Data</p>
            <p>This includes data relating to the transactions you have carried out with us, such as details about payments to and from you and other details of products and services you have purchased from us. </p>

            <p>(E) Technical Data</p>
            <p>This includes more technical data that we may obtain when you make use of our website, such as your internet protocol (IP)
              address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website. </p>
            <p>(F) Profile Data</p>
            <p>This includes the data that we receive if and when you create a profile on our website and make use of that profile, such as your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses. </p>

            <p>If you are happy to consent to our use of your sensitive information, you will also be able to withdraw your consent at any time. However, as this will prevent us from providing your holiday, we will be required to treat any withdrawal of consent as a cancellation of your booking and the cancellation charges in the Booking Terms and Conditions for your booking will become payable </p>

            <p class="head">5.How Your Personal Data is Collected</p>

            <p>We use different methods to collect data from and about you including through: </p>

            <p class="strong">Direct interactions </p>

            <p>You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you: </p>
            <ul>
              <li>
                <p>make a booking of arrangements;</p>
              </li>
              <li>
                <p>request a quote for arrangements;</p>
              </li>
              <li>
                <p>subscribe to our newsletter or other publications;</p>
              </li>
              <li>
                <p>request marketing;</p>
              </li>
              <li>
                <p>enter a competition, promotion or survey;</p>
              </li>
              <li>
                <p>give us some feedback; or</p>
              </li>
              <li>
                <p>use our online payment and client portals.</p>
              </li>
            </ul>
            <p class="strong">Automated technologies or interactions</p>
            <p>As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. </p>

            <p>We collect this personal data by using cookies, or other similar technologies. Please see our cookie policy for further details. </p>
            <p class="strong">Third parties</p>
            <p>We may receive personal data about you from various third parties as set out below: </p>

            <p>Technical Data from the following parties </p>
            <ul>
              <li>
                <p>analytics providers such as Google; and </p>
              </li>
              <li>
                <p>advertising networks based around the world.</p>
              </li>
            </ul>
            <p class="strong">Marketing</p>
            <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. </p>
            <p class="strong">Promotional offers from us</p>
            <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing). </p>

            <p>You will receive marketing communications from us if you have made a booking with us and, in each case, you have opted in to receive that marketing. </p>

            <p>Where you have not made a booking with us but you are interested in receiving marketing communications from us about products, services and offers, we will get your express opt-in consent. ;</p>
            <p class="strong">Third-party marketing</p>
            <p>We will get your express opt-in consent before we share your personal data with any third party for marketing purposes. </p>
            <p class="strong">Monitoring communications</p>
            <p>We may monitor, record, store and use any [telephone,] email or other communication with you in order to check any instructions given to us, for training purposes, for crime prevention and to improve the quality of our customer service. </p>
            <p class="head">6. International transfers</p>
            <p>We do transfer your personal data to different countries around the world to the suppliers fulfilling or providing your travel arrangements in order to satisfy our contract with you and for those suppliers to be able to provide you with the travel arrangements you have booked if you are travelling outside of your country of residence. </p>

            <p>Whenever we transfer your personal data, we ensure a similar or more extensive degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented: </p>
            <ul>
              <li>
                <p>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data.</p>
              </li>
              <li>
                <p>Where we use certain service providers, we may use specific contracts which give personal data the same protection, or an increased amount of protection as it has in your country of residence. </p>
              </li>
            </ul>
            <p>Please contact us if you want further information on the specific mechanism used by us when transferring your personal data to other countries. </p>
            <p class="head">7. Data security</p>

            <p>We have put in place appropriate technical and operational security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. </p>

            <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so. </p>
            <p class="head">8. Data retention</p>
            <p class="head">How long will you use my personal data for?</p>
            <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. </p>

            <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements. </p>

            <p>In addition to the above, by law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers for tax purposes. </p>

            <p>In some circumstances you can ask us to delete your data: see Request erasure below for further information. </p>

            <p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you. </p>
          </div>
        </div>
      </div>


      <h2>Refund Policy</h2>
      <div class="row">
        <div class="col-md-12">
          <div class="content1">

            <div class="py-4">

              <p class="mb-3">Customers are not eligible for any refund if</p>
              <ul type="disc">
                <li>Their application has already been submitted to the Government website.</li>
                <li>Their application has been rejected, on hold, or taking longer than expected time to process.</li>
                <li>Customer’s change of mind.</li>
                <li>The information provided by the applicant is incorrect.</li>

              </ul>
              <p>From our side, we make sure that your application is filled out correctly and that all the translation to English is done without any mistakes. We also make sure your photos and documents will be submitted in an acceptable format and guidelines.</p>
              <p class="mb-3">Please note the following points:</p>
              <ul>
                <li>Application approval or rejection is the respective government's sole decision, and we have no control over the outcome.</li>
                <li>Immigration office normally take 48-72 hours in processing applications (in some circumstances it can take longer as well)</li>
                <li>We are not immigration agents and do not provide any immigration advice.</li>
                <li>Our agents provide language translation services for applications submitted in languages other than English. We make sure this is done error-free.</li>

              </ul>
              <p>If you have any questions, please feel free to fill out our contact form and we will make sure you will be looked after properly.</p>
            </div>

          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={onHide}>Close</Button>
    </Modal.Footer>
  </Modal>
  );
};

export default DetailModal;
