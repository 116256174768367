import React, { useRef, useState } from "react";
import credit from "../../src/auth/Credit-Card-Icons.png";
import card from "../../src/auth/card.diners.png";
import creditcardicon from "../../src/auth/card.png";
import visa from "../../src/auth/card.visa.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import carddefalut from "../../src/auth/card.default.png";
import Select from "react-select";
import { getNames } from "country-list";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormControl } from "react-bootstrap";
import API from "../utils/utils";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

import { CitySelect, CountrySelect, StateSelect, LanguageSelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { useNavigate } from "react-router-dom";

const PaymentForm = () => {
  // navigate(`/application-form?action=${requestId}&mode=edit&tdId=${tdId}`);
  // <button className="btn-primary btn-next1 w-100" id="back_edit" type="button" onClick={() => editClick(selectedApplicant?.request_id, selectedApplicant?.td_id)} >
  //         <FaAngleLeft style={{ fontSize: '19px' }} />&nbsp;&nbsp;<span style={{ fontSize: '17px' }}>BACK &amp; EDIT</span>
  //       </button>

  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const countryOptions = getNames().map((country) => ({ value: country, label: country }));

  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    cardholderName: "",
    cardNumber: "",
    expiryDate: null,
    cvv: "",
    customerName: "",
    address: "",
    countrys: "",
    city: "",
    state: "",
    pincode: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, countrys: selectedOption.value });
    // if (formErrors.countrys) {
    //   setFormErrors({ ...formErrors, countrys: '' });
    // }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.cardholderName) {
      errors.cardholderName = "Cardholder name is required";
    }

    if (!formData.cardNumber) {
      errors.cardNumber = "Card number is required";
      // } else if (!/^\d{16}$/.test(formData.cardNumber)) {
    } else if (!/^\d{16}$/.test(formData.cardNumber.replace(/\s/g, ""))) {
      errors.cardNumber = "Card number must be 16 digits";
    }

    if (!formData.expiryDate) {
      errors.expiryDate = "Expiry date is required";
    } else {
      const expMonth = formData.expiryDate.getMonth() + 1; // Months are 0-based
      const expYear = formData.expiryDate.getFullYear();

      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-based
      const currentYear = currentDate.getFullYear();

      if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
        errors.expiryDate = "Card Expired";
      }
    }

    if (!formData.cvv) {
      errors.cvv = "CVV is required";
    } else if (!/^\d{3}$/.test(formData.cvv)) {
      errors.cvv = "CVV must be 3 digits";
    }

    if (!formData.customerName) {
      errors.customerName = "Customer name is required";
    }

    if (!formData.address) {
      errors.address = "Address is required";
    }

    if (country == null) errors.countrys = "Country is required";

    if (state == null) errors.state = "State is required";

    // if (!formData.countrys) {
    //   errors.countrys = "Country is required";
    // }

    if (!formData.city) {
      errors.city = "City is required";
    }

    // if (!formData.state) {
    //   errors.state = "State is required";
    // }

    if (!formData.pincode) {
      errors.pincode = "Pincode is required";
    } 
    // else if (!/^\d{5,6}$/.test(formData.pincode)) {
    //   errors.pincode = "Pincode must be 5 or 6 digits";
    // }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setDisableSubmit(true);
      setLoading(true);
      try {
        // console.log("Form submitted:", formData);
        const { cardholderName, cardNumber, expiryDate, cvv, customerName, address, countrys, city, pincode } = formData;
        // state,

        const date = new Date(expiryDate);
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear().toString().slice(-2);
        const expDate = month + year;

        const mainsessionId = sessionStorage.getItem("mainsessionId");

        const formDataToSend = new FormData();
        formDataToSend.append("main_session_id", mainsessionId);
        formDataToSend.append("customerName", customerName);
        formDataToSend.append("customerCountry", country.iso2);
        formDataToSend.append("customerState", state.state_code);
        formDataToSend.append("customerCity", city);
        formDataToSend.append("customerAddress", address);
        formDataToSend.append("customerPostCode", pincode);
        formDataToSend.append("paymentCardName", cardholderName);
        formDataToSend.append("paymentCardNumber", cardNumber.replace(/\s/g, ""));
        formDataToSend.append("paymentCardExpiry", expDate);
        formDataToSend.append("paymentCardCSC", cvv);

        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.Payment}`, formDataToSend, {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        });
        // console.log(response.data);
        if (response.data.status == true) {
          sessionStorage.setItem("payment", true);
          toast.success(response.data.message);
          setPaymentError(null);
          setCountry(null);
          setState(null);
          setFormData({
            cardholderName: "",
            cardNumber: "",
            expiryDate: null,
            cvv: "",
            customerName: "",
            address: "",
            countrys: "",
            city: "",
            state: "",
            pincode: "",
          });
          navigate("/thankyou");
        } else {
          setPaymentError(response.data.message);
        }
      } catch (error) {
        console.error("Payment error: ", error);
      }
      setLoading(false);
      setDisableSubmit(false);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "cvv" && value.length > 3) return;
    if (id === "cardNumber") {
      // Remove all non-digit characters
      const digitsOnly = value.replace(/\D/g, "");

      // Format the value with a space after every 4 digits
      const formattedValue = digitsOnly.replace(/(.{4})/g, "$1 ").trim();
      setFormData({ ...formData, [id]: formattedValue });
    } else setFormData({ ...formData, [id]: value });
    if (formErrors[id]) {
      setFormErrors({ ...formErrors, [id]: "" });
    }
  };
  return (
    <>
      <style>{`
        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type=number] {
          -moz-appearance: textfield; /* Hide spinner on Firefox */
        }
      `}</style>
      <Loader show={loading} />
      <div className="card">
        <div className="card-body pt-4">
          <div className="border secure_payment_div">
            <div className="secure_payment_card d-flex">
              <p className="card-title-secure-payment">SECURE YOUR PAYMENT</p>
              <img src={credit} height="30" width="93" className="cardimg" alt="Credit Card Icons" />
            </div>

            <form style={{ padding: "35px 35px" }} onSubmit={handleSubmit}>
              <p className="text-center-paragrabh">
                <b>Note:</b> Please enter the exact details which are associated with your payment card (debit/credit) in the form given below. Otherwise, there will be higher
                chances of payment failure. We are accepting only MasterCard and Visa credit and debit cards.
              </p>
              <Form.Group controlId="cardholderName">
                <Form.Label>Cardholder Name</Form.Label>
                <FormControl type="text" value={formData.cardholderName} onChange={handleChange} placeholder="Cardholder Name" isInvalid={!!formErrors.cardholderName} />
                <FormControl.Feedback className="invalid-feedback text-danger f_err fw-bold fst-italic" type="invalid">
                  {formErrors.cardholderName}
                </FormControl.Feedback>
              </Form.Group>

              <div className="credit-card-input-container">
                <div id="mwCardTypes" style={{ width: "100%", display: "block", float: "left", marginLeft: "0px" }}>
                  <label className="element-label d-flex justify-content-end" id="paymentCardNumberLabel">
                    <div id="mwCardTypeSpacer" style={{ width: "448.549px", display: "block", height: "18px", float: "left", marginTop: "3px", marginBottom: "0px" }}>
                      {" "}
                    </div>
                    <img
                      id="mwCardTypeVisa"
                      src={visa}
                      className="mwCardType"
                      style={{ display: "block", width: "33.6px", height: "24px", marginTop: "3px", marginBottom: "0px" }}
                      alt="Visa"
                    />
                    <img
                      id="mwCardTypeMastercard"
                      src={creditcardicon}
                      className="mwCardType"
                      style={{ display: "block", width: "33.6px", height: "24px", marginTop: "3px", marginBottom: "0px" }}
                      alt="Mastercard"
                    />
                    <img
                      id="mwCardTypeDiners"
                      src={card}
                      className="mwCardType"
                      style={{ display: "block", width: "33.6px", height: "24px", marginTop: "3px", marginBottom: "0px" }}
                      alt="Diners"
                    />
                  </label>
                </div>
                <div className={`credit-card-input ${formErrors.cardNumber ? "is-invalid" : ""}`}>
                  <img src={carddefalut} alt="Credit Card Icon" className="credit-card-icon" style={{ width: "38px" }} />
                  <input
                    type="text"
                    className={`credit-card-number ${formErrors.cardNumber ? "is-invalid" : ""}`}
                    placeholder="4111 2222 3333 5555"
                    id="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleChange}
                    isInvalid={!!formErrors.cardNumber}
                    maxLength="19" // Limit input length considering the spaces
                  />

                  <DatePicker
                    selected={formData.expiryDate}
                    onChange={(date) => setFormData({ ...formData, expiryDate: date })}
                    dateFormat="MM/yy"
                    className={`credit-card-expiry ${formErrors.expiryDate ? "is-invalid" : ""}`}
                    placeholderText="MMYY"
                    showMonthYearPicker
                  />
                  <input
                    type="number"
                    className={`credit-card-expiry ${formErrors.cvv ? "is-invalid" : ""}`}
                    placeholder="CVV"
                    id="cvv"
                    value={formData.cvv}
                    onChange={handleChange}
                    min={0}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  {formErrors.cardNumber && <div className="text-danger">{formErrors.cardNumber}</div>}
                  {formErrors.expiryDate && <div className="text-danger">{formErrors.expiryDate}</div>}
                  {formErrors.cvv && <div className="text-danger">{formErrors.cvv}</div>}
                </div>
              </div>

              <div className="mb-2">
                <Form.Group controlId="customerName">
                  <Form.Label>Customer Name</Form.Label>
                  <FormControl type="text" value={formData.customerName} onChange={handleChange} placeholder="Customer Name" isInvalid={!!formErrors.customerName} />
                  <FormControl.Feedback className="invalid-feedback text-danger f_err fw-bold fst-italic" type="invalid">
                    {formErrors.customerName}
                  </FormControl.Feedback>
                </Form.Group>
              </div>
              <div className="mb-2">
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>
                  <FormControl type="text" value={formData.address} onChange={handleChange} placeholder="Address" isInvalid={!!formErrors.address} />
                  <FormControl.Feedback className="invalid-feedback text-danger f_err fw-bold fst-italic" type="invalid">
                    {formErrors.address}
                  </FormControl.Feedback>
                </Form.Group>
              </div>
              <div className="row">
                <div className="mb-2 col-lg-6">
                  <Form.Group controlId="countrys">
                    <Form.Label>Country</Form.Label>
                    <CountrySelect
                      // inputClassName={`payment_country ${formErrors.countrys ? "is-invalid" : ""}`}
                      id="countrys"
                      name="countrys"
                      value={country}
                      style={{ border: formErrors.countrys ? "1px solid #ff0000" : "0px", borderRadius: "0px" }}
                      onChange={(e) => {
                        // console.log("country id", e.id, e);
                        setCountry(null);
                        setCountry(e);
                        setState(null);
                      }}
                      placeHolder="Select Country"
                    />
                    {formErrors.countrys && <div className="invalid-feedback text-danger f_err fw-bold fst-italic d-block">{formErrors.countrys}</div>}

                    {/* <div className="d-flex flex-column">
                      <Form.Control as="select" id="countrys" name="countrys" value={formData.countrys} onChange={handleChange} className={formErrors.countrys ? "is-invalid" : ""}>
                        <option value="">Select Country/Region of Your Travel Document</option>
                        <option value="1">Afghanistan</option>
                        <option value="2">Antigua and Barbuda</option>
                        <option value="3">Armenia</option>
                        <option value="4">Australia</option>
                        <option value="5">Bahamas</option>
                        <option value="7">Bangladesh</option>
                        <option value="8">Barbados</option>
                        <option value="9">Bermuda</option>
                        <option value="10">Bhutan</option>
                        <option value="11">Cambodia</option>
                        <option value="48">Hong Kong (BN(O))</option>
                        <option value="13">Cape Verde</option>
                        <option value="14">China</option>
                        <option value="15">Dominica</option>
                        <option value="16">Dominican Republic</option>
                        <option value="17">East Timor</option>
                        <option value="18">Egypt</option>
                        <option value="19">Equatorial Guinea</option>
                        <option value="20">Fiji</option>
                        <option value="21">Grenada</option>
                        <option value="23">Hong</option>
                        <option value="24">India</option>
                        <option value="25">Jamaica</option>
                        <option value="26">Libya</option>
                        <option value="27">Maldives</option>
                        <option value="28">Mauritius</option>
                        <option value="29">Nepal</option>
                        <option value="31">Pakistan</option>
                        <option value="32">Palestine</option>
                        <option value="33">Philippines</option>
                        <option value="34">Saint Kitts and Nevis</option>
                        <option value="35">Saint Lucia</option>
                        <option value="47">Greek Cypriot Administration of Southern Cyprus</option>
                        <option value="37">Senegal</option>
                        <option value="38">Solomon Islands</option>
                        <option value="39">Sri Lanka</option>
                        <option value="46">Algeria</option>
                        <option value="41">United Arab Emirates</option>
                        <option value="42">Vanuatu</option>
                        <option value="43">Vietnam</option>
                        <option value="44">Yemen</option>
                        <option value="45">Suriname</option>
                      </Form.Control>
                      {formErrors.countrys && <div className="invalid-feedback text-danger f_err fw-bold fst-italic d-block">{formErrors.countrys}</div>}
                    </div> */}
                  </Form.Group>
                </div>
                <div className="mb-2 col-lg-6">
                  <Form.Group controlId="state">
                    <Form.Label>State</Form.Label>
                    <StateSelect
                      countryid={country?.id}
                      id="state"
                      name="state"
                      // inputClassName={`${formErrors.state ? "is-invalid" : ""}`}
                      // value={state}
                      style={{ border: formErrors.state ? "1px solid #ff0000" : "0px", borderRadius: "0px" }}
                      onChange={(e) => {
                        setState(e);
                        // console.log("state id", e, e.id);
                      }}
                      placeHolder="Select State"
                    />
                    {formErrors.state && <div className="invalid-feedback text-danger f_err fw-bold fst-italic d-block">{formErrors.state}</div>}

                    {/* <FormControl type="text" value={formData.state} onChange={handleChange} placeholder="State" isInvalid={!!formErrors.state} />
                    <FormControl.Feedback className="invalid-feedback text-danger f_err fw-bold fst-italic" type="invalid">
                      {formErrors.state}
                    </FormControl.Feedback> */}
                  </Form.Group>
                </div>
                <div className="mb-2 col-lg-6">
                  <Form.Group controlId="city">
                    <Form.Label>City</Form.Label>
                    <FormControl type="text" value={formData.city} onChange={handleChange} placeholder="City" isInvalid={!!formErrors.city} />
                    <FormControl.Feedback className="invalid-feedback text-danger f_err fw-bold fst-italic" type="invalid">
                      {formErrors.city}
                    </FormControl.Feedback>
                  </Form.Group>
                </div>

                <div className="mb-2 col-lg-6">
                  <Form.Group controlId="pincode">
                    <Form.Label>Pincode</Form.Label>
                    <FormControl type="text" value={formData.pincode} onChange={handleChange} placeholder="Pincode" isInvalid={!!formErrors.pincode} />
                    <FormControl.Feedback className="invalid-feedback text-danger f_err fw-bold fst-italic" type="invalid">
                      {formErrors.pincode}
                    </FormControl.Feedback>
                  </Form.Group>
                </div>
              </div>
              {paymentError && <div className="text-danger fw-medium">{paymentError}</div>}
              <button type="submit" className="btn btn-warning mt-4 w-100" disabled={disableSubmit}>
                Pay Now
              </button>
            </form>
            <div>
              <p className="reviewtxt">
                A review of your file will start once payment is successful. You will receive an email which will contain your Turkey eVisa approval confirmation by the Turkish
                Immigration Authorities. The eVisa is an entry requirement to be able to travel to Turkey by air. Once your eVisa is approved, it will be delivered to your email
                and linked to your passport.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
