import React from 'react';

const ProgressBar = ({ currentStep }) => {

  return (
    <div className="progress-bar-container pt-3">
    <div className={`step ${currentStep === 1 ? 'current-step' : currentStep > 1 ? 'completed' : 'in-completed'}`}>
      <div className="step-number mx-3">1</div>
      <span>Submit Application Online</span>
    </div>
    <div className={`step ${currentStep === 2 ? 'current-step' : currentStep > 2 ? 'completed' : 'in-completed'}`}>
      <div className="step-number mx-3">2</div>
      <span>Review and Confirm Payment</span>
    </div>
    <div className={`step ${currentStep === 3 ? 'current-step' : currentStep > 3 ? 'completed' : 'in-completed'}`}>
      <div className="step-number mx-3">3</div>
      <span>Receive Approved Visa</span>
    </div>
  </div>

  );
};

export default ProgressBar;
