const API = {
  AUTHORIZATION_TOKEN: "hXuRUGsEGuhGf6KG",

  PROXY_URL: `${process.env.REACT_APP_PROXY_URL}`,
  BASE_URL: `${process.env.REACT_APP_BASE_URL}`,

  ENDPOINTS: {
    add_Applicant: "/api.php",

    // Summary Payment
    Get_Summary: "/api.php?action=getsingleDataview&main_session_id=",
    Get_Fees: "/api.php?action=get_fees_from_country&main_session_id=",
    Payment :"/api.php?action=get_payment"
  },
};

export default API;
