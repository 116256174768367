import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import evisa from "../../src/auth/turkey_evisa.png";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg  bg-white navbar-light shadow border-top border-5 border-danger sticky-top p-0" style={{ position: 'initial', top: '-100px' }}>
      <a href="#" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
        <img src={evisa} alt="Turkey eVisa" className="turkey-evisa-images" />
      </a>
      <button
        type="button"
        className="navbar-toggler me-4 d-none"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          {/* Add your nav links here */}
        </div>
        <h4 className="m-0 pe-lg-5 d-none d-lg-block">
          <img src="https://travel-canada-services.com/img/usa-map.png" alt="USA Map" className='travel-canada-service-turkey-image'/> English
        </h4>
      </div>
    </nav>
  );
};

export default Navbar;
