// Step3.js
import React from "react";
import ProgressBar from "../multistepForm";
import PaymentForm from "../ReviewandConfirm Payment";
import Summary from "../summery";
import { ToastContainer } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Step3 = ({ prevStep, currentStep }) => {
  const navigate= useNavigate()
  return (
    <>
      <ToastContainer />

      <div className="container my-5">
        <div className="row responsive">
          <div className="col">
            <button className="btn-primary btn-next1 w-auto mb-3" id="back_edit" type="button" onClick={() => navigate('/preview')}>
              <FaAngleLeft style={{ fontSize: "19px" }} />
              &nbsp;&nbsp;<span style={{ fontSize: "17px" }}>BACK</span>&nbsp;&nbsp;
            </button>
          </div>
        </div>
        <div className="row responsive">
          <div className="col-md-8">
            <PaymentForm />
          </div>
          <div className="col-md-4 responsive-body-summary">
            <Summary />
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;
