import React from "react";

const Loader = ({ show }) => {
  if (!show) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.7)", 
        zIndex: 10000, 
      }}
    >
      {/* <div class="d-flex justify-content-center"> */}
        <div class="spinner-border text-danger" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      {/* </div> */}
    </div>
  );
};

export default Loader;
