import React, { useEffect, useState } from 'react';
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaCheck, FaUser } from 'react-icons/fa';
import ProgressBar from '../progressBar';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../utils/utils';
import axios from 'axios';

const Step2 = () => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(2);
  const [data, setData] = useState({});
  const [apiData, setApiData] = useState([]);
  const [prerequisites, setPrerequisites] = useState([]);
  const [selectedApplicant, setSelectedApplicant] = useState(null); // Track the selected applicant
  const urlParams = sessionStorage.getItem("mainsessionId");

  const getRequestId = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.add_Applicant}?action=getsingleDataview&main_session_id=${urlParams}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        }
      });

      if (response.data.status === "true") {
        const fetchedData = response.data.data;
        setApiData(fetchedData);
        setPrerequisites(fetchedData[0].questions);
        const newRequestId = response.data.RequestID;

        sessionStorage.setItem('requestId', newRequestId);

        setTimeout(() => {
          sessionStorage.removeItem('requestId');
        }, 15 * 60 * 1000);

        setSelectedApplicant(fetchedData[0]); // Set the first applicant's data by default
        setData(fetchedData[0]);
      } else {
        console.error('Failed to fetch data:', response.data.message);
      }
    } catch (error) {
      console.error('Error generating request ID:', error);
    }
  };

  useEffect(() => {
    getRequestId();
  }, []);

  const navigate = useNavigate();

  const nextStep = () => {
    setLoading(true);
    setTimeout(() => {
      setCurrentStep((prev) => prev + 1);
      setLoading(false);
      navigate("/order_final");
    }, 1000);
  };

  const editBackClick = () => {
    navigate(`/application-form?mode=edit`);
    setCurrentStep((prev) => prev - 1);
  };
  const editClick = (requestId, tdId) => {
    navigate(`/application-form?action=${requestId}&mode=edit&tdId=${tdId}`);
    setCurrentStep((prev) => prev - 1);
  };

  const AddClick = () => {
    navigate(`/application-form?mode=add`);
    setCurrentStep((prev) => prev - 1);
  };

  const handleApplicantClick = (applicant) => {
    setSelectedApplicant(applicant);
  };

  return (
    <>
      <div className='container mt-5'>
        <div className="row justify-content-center align-items-center mb-5">
          <div className="col-md-12">
            <h3 className="preview_heading pt-4">Already Added Applicants</h3>
            <div className="card mt-2">
              <div className="card-body">
                <div className="row mb-3">
                  {apiData && apiData.length > 0 ? (
                    apiData.map((applicant, index) => (
                      <div className="col-lg-3 col-md-6 col-sm mt-2" key={index} onClick={() => handleApplicantClick(applicant)}>
                        <div className="secure_payment">
                          <div className="row">
                            <div className="col-4 contentdiv">
                              <h6 className="text-white pt-3" style={{ fontSize: '12px', paddingLeft: '12px' }}>
                                &nbsp;&nbsp;Applicant {index + 1}
                              </h6>
                            </div>
                            <div className="col-8 contentdiv">
                              <h6 className="text-white pt-2 text-center mb-0" style={{ fontSize: '13px' }}>
                                &nbsp;&nbsp;{applicant.request_id || 'No Request ID'}
                              </h6>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <a  style={{ fontSize: '12px' }} className="fw-bold text-decoration-underline"   onClick={() => editClick(applicant.request_id, applicant.td_id)}>
                                EDIT
                              </a>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Link to={"/order_final"} style={{ fontSize: '12px' }} className="fw-bold text-decoration-underline">
                                CONTINUE
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No applicants found.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl sub-step-header-container py-5">
        <div className='container'>
          <ProgressBar currentStep={currentStep} />
          <div className='col-md-12'>
            <h3 className="preview_heading mt-5">Personal Information</h3>
            <div className="card mt-2">
              <div className="card-body">
                <div className="row pt-1">
                  <div className="col-5 mb-3 em1">
                    <h6>Given/First Name(s)</h6>
                    <p className="text-muted">{selectedApplicant?.first_name || 'N/A'}</p>
                  </div>
                  <div className="col-4 mb-3 em2">
                    <h6>Surname(s)</h6>
                    <p className="text-muted">{selectedApplicant?.last_name || 'N/A'}</p>
                  </div>
                  <div className="col-3 mb-3 ph1">
                    <h6>Email address</h6>
                    <p className="text-muted">{selectedApplicant?.email_address || 'N/A'}</p>
                  </div>
                </div>
                <div className="row pt-1">
                  <div className="col-5 mb-3 em1">
                    <h6>Phone Number</h6>
                    <p className="text-muted">{selectedApplicant?.phone || 'N/A'}</p>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="preview_heading mt-5">Country/Region</h3>
            <div className="card mt-2">
              <div className="card-body p-4">
                <div className="row pt-1">
                  <div className="col-5 mb-3 em1">
                    <h6>Country/Region</h6>
                    <p className="text-muted">{selectedApplicant?.country_name || 'N/A'}</p>
                  </div>
                  <div className="col-4 mb-3 em2">
                    <h6>Travel Document</h6>
                    <p className="text-muted">Ordinary Passport</p>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="preview_heading mt-5">Date Of Arrival</h3>
            <div className="card mt-2">
              <div className="card-body p-4">
                <div className="row pt-1">
                  <div className="col-5 mb-3 em1">
                    <h6>Arrival Date in Turkiye</h6>
                    <p className="text-muted">{selectedApplicant?.arrival_date || 'N/A'}</p>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="preview_heading mt-5">Prerequisites</h3>
            <div className="card mt-2">
              <div className="card-body p-4">
                <div className="row pt-1">
                  <div className="col-12 mb-3">
                    {selectedApplicant?.questions && selectedApplicant?.questions.length > 0 ? (
                      selectedApplicant.questions.map((question, index) => (
                        <p key={index}><FaCheck className='me-3' />{question}</p>
                      ))
                    ) : (
                      <p>No prerequisites available.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <h3 className="preview_heading mt-5">Applicant Details</h3>
            <div className="card mt-2">
              <div className="card-body p-4">
                <div className="row pt-1">
                  <div className="col-6 mb-3 em1">
                    <h6>Date of Birth :</h6>
                    <p className="text-muted">{selectedApplicant?.dob}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
                    <h6>Place of Birth :</h6>
                    <p className="text-muted">{selectedApplicant?.place_of_birth}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
                    <h6>Mother's Name :</h6>
                    <p className="text-muted">{selectedApplicant?.mothers_name}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
                    <h6>Father's Name :</h6>
                    <p className="text-muted">{selectedApplicant?.fathers_name}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
                    <h6>Passport Number :</h6>
                    <p className="text-muted">{selectedApplicant?.passport_number}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
                    <h6>Passport Issue Date :</h6>
                    <p className="text-muted">{selectedApplicant?.passport_issue_date}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
                    <h6>Passport Expiry Date :</h6>
                    <p className="text-muted">{selectedApplicant?.passport_expiry_date}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
                    <h6>Type of Supporting Doc. :</h6>
                    <p className="text-muted">{selectedApplicant?.type_of_supporting_doc|| 'N/A'}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
                    <h6>Supporting Doc. From :</h6>
                    <p className="text-muted">{selectedApplicant?.supporting_doc_from|| 'N/A'}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
                    <h6>Supporting Doc. No. :</h6>
                    <p className="text-muted">{selectedApplicant?.supporting_doc_no|| 'N/A'}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
  <h6>Supp. Doc. Expiry Date :</h6>
  <p className="text-muted">
    {selectedApplicant?.unlimited==="true" ? 'Unlimited' : selectedApplicant?.supporting_doc_expiry_date || 'N/A'}
  </p>
</div>

                  <div className="col-6 mb-3 em1">
                    <h6>Address :</h6>
                    <p className="text-muted">{selectedApplicant?.address}</p>
                  </div>
                  <div className="col-6 mb-3 em1">
                    <h6>IP Address :</h6>
                    <p className="text-muted">{selectedApplicant?.ip_address}</p>
                  </div>
                </div>
                <div className="row pt-1">
    <div className="col-md-4 col-lg-2 col-12 mb-2">
      <a>
        <button className="btn-primary btn-next1 w-100" id="back_edit" type="button" onClick={() => editClick(selectedApplicant?.request_id, selectedApplicant?.td_id)} >
          <FaAngleLeft style={{ fontSize: '19px' }} />&nbsp;&nbsp;<span style={{ fontSize: '17px' }}>BACK &amp; EDIT</span>
        </button>
      </a>
    </div>
    <div className="col-md-5 col-lg-3 col-12 mb-2">
      <a>
        <button className="btn-primary btn-next1 w-100" id="addapplicant" type="button" onClick={AddClick}>
          <FaUser style={{ fontSize: '19px' }} /> &nbsp;&nbsp; <span style={{ fontSize: '17px' }}>Add Another Applicant</span>
        </button>
      </a>
    </div>
    <div className='col-lg-4 col-md-0'></div>
    <div className="col-12 col-lg-3 col-md-4 mb-2">
      <a>
        <button className="btn-next2 w-100 sb1" id="submit_and_pay" type='button' onClick={nextStep}>
          <span style={{ fontSize: '17px' }}>SUBMIT &amp; PAY</span>&nbsp;&nbsp; <FaAngleRight style={{ fontSize: '19px' }} />
        </button>
      </a>
    </div>
  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2;