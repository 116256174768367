import React, { useEffect } from "react";
import thankyou from "../auth/thankyou.svg";
import { useNavigate } from "react-router-dom";
const ThankYou = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // if(sessionStorage.getItem("mainsessionId")==null) navigate()
    sessionStorage.removeItem("mainsessionId");
    sessionStorage.removeItem("payment");
  }, []);

  const handleBtnClick = async () => {
    sessionStorage.removeItem("payment");
    navigate("/application-form");
  };
  return (
    <>
      <div class="design_container">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="response-text">
                <div class="contentbox my-5">
                  <div class="req_id">
                    <h1 style={{ marginTop: "20px" }}>Your Turkish Journey Begins!</h1>
                    <p style={{ fontSize: "16px" }}>
                      We are thrilled to inform you that your application for a Turkish eVisa has been successfully submitted. The enchanting landscapes and rich history of Turkey
                      are now within your reach.
                    </p>
                  </div>
                  <div class="wrapper">
                    <div class="row py-3">
                      <div class="col-md-12">
                        <h6>What Comes Next?</h6>
                        <ul>
                          <li>
                            <p>**Application Processing**: Your eVisa application is currently under review.</p>
                          </li>
                          <li>
                            <p>
                              **Email Confirmation**: Keep an eye on your inbox. You will receive a confirmation email with your eVisa details from the Turkish Immigration
                              Authority within the next 24 hours. The approval process may take up to 72 hours, so we appreciate your patience. Remember to check your spam folder
                              just in case.
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-12">
                        {/* text-end */}
                        {/* <a href="application-form.php">Apply Another Turkey eVisa</a> */}
                        <div className="button-group m-0 justify-content-start">
                          <button className="btn-next" onClick={handleBtnClick}>
                            Apply Another Turkey eVisa
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h6>Before You Go:</h6>
                  <ul>
                    <li>
                      <p>
                        **Print Your eVisa**: Although your eVisa is electronically linked to your passport, we recommend printing a copy to carry with you during your travels.
                      </p>
                    </li>
                    <li>
                      <p>**Embark on Your Adventure**: Prepare to immerse yourself in the vibrant culture, breathtaking scenery, and warm hospitality of Turkey.</p>
                    </li>
                  </ul>
                  <h6>Need Help?</h6>
                  <ul>
                    <li>
                      <p>
                        Our customer support team is here for you 24/7. Should you have any inquiries or require assistance with your eVisa, please do not hesitate to contact us.
                      </p>
                    </li>
                    <li>
                      <p>Safe travels, and thank you for choosing our services for your visit to Turkey.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

    // <div className="align-content-center" style={{ height: 'calc(100vh - 202px)' }}>
    //   <div className="row  justify-content-center m-0">
    //     {/* row-cols-2 */}
    //     <div className="col-md-6 col-lg-4 col-12 text-center">
    //       <img src={thankyou} className="img-fluid h-75 " />
    //       <div className="text-center my-4 " style={{ fontFamily: "math", fontSize: "x-large" }}>
    //         Thank You For Applying E-Visa!
    //       </div>
    //       <button className="btn btn-primary">Apply for Another e-Visa</button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ThankYou;
